import z from 'zod'
import { computed, reactive } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { useWeightStep } from './weight'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useBreedStep } from './breed'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/phoenix/dogs'

export interface DogAgeData {
  identifier: string
  dateOfBirth: string
  dateOfBirthIsApproximate: boolean
}

const StepSchema = z
  .array(
    z.object({
      dateOfBirth: DogProperties.shape.dateOfBirth,
      dateOfBirthIsApproximate: DogProperties.shape.dateOfBirthIsApproximate,
    }),
  )
  .min(1)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineAgeStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const data = reactive<DogAgeData[]>(
    dogsStore.dogs.map(({ identifier, dateOfBirth, dateOfBirthIsApproximate }) => ({
      identifier,
      dateOfBirth: dateOfBirth ?? '',
      dateOfBirthIsApproximate: dateOfBirthIsApproximate ?? false,
    })),
  )

  const valid = computed(() => {
    return StepSchema.safeParse(data).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useBreedStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useWeightStep()
  }

  const updateDateOfBirth = async (dog: DogEntity, dob: string, approx: boolean): Promise<void> => {
    const dogData = data.find((d) => d.identifier === dog.identifier)

    if (!dogData) {
      return
    }

    Object.assign(dogData, {
      dateOfBirth: new Date(dob).toISOString().split('T')[0],
      dateOfBirthIsApproximate: approx,
    })

    if (!valid.value) {
      return
    }

    await dogsStore.updateDog(dog, dogData)
  }

  return {
    name: StepName.Age,
    busy,
    dogs: dogsStore.dogs,
    breeds: dogsStore.breeds,
    valid,
    getPreviousStep,
    getNextStep,
    updateDateOfBirth,
  }
}

export type AgeStep = ReturnType<typeof defineAgeStep>

export const useAgeStep = registerStep(defineAgeStep)
