<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaInput } from '@lyka/ui'
import StepContainer from '../StepContainer.vue'
import StepHeading from '../StepHeading.vue'
import StepNavigation from '../StepNavigation.vue'
import type { LocationStep } from '@/steps/phoenix/location'

const props = defineProps<{
  step: LocationStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const submitting = ref(false)

const heading = computed(() => {
  const count = props.step.dogs.length
  const [first, second] = props.step.dogs.map(({ name }) => name)

  switch (count) {
    case 1: {
      return `Where does ${first} live?`
    }
    case 2: {
      return `Hello, ${first} and ${second}! And where do they live?`
    }
    default: {
      return 'Hello! And where do they live?'
    }
  }
})

const postcode = computed({
  get: () => props.step.postcode.value,
  set: (value) => {
    props.step.updatePostcode(value)
  },
})

const suburb = computed({
  get: () => props.step.suburb.value,
  set: (value) => {
    props.step.updateSuburb(value)
  },
})

const submit = async (): Promise<void> => {
  submitting.value = true

  try {
    await props.step.submit()

    emits('next')
  } finally {
    submitting.value = false
  }
}
</script>

<template>
  <form id="form-location" v-submit @submit.prevent="submit">
    <StepContainer>
      <StepHeading>{{ heading }}</StepHeading>

      <LykaInput
        v-model="postcode"
        name="postcode"
        minlength="4"
        maxlength="4"
        required
        type="text"
        pattern="[0-9]*"
        placeholder="Enter your postcode"
        error-message="Please enter your postcode"
      />
      <LykaInput
        v-model="suburb"
        name="suburb"
        required
        type="text"
        placeholder="Enter your suburb"
        error-message="Please enter your suburb"
      />
    </StepContainer>

    <StepNavigation
      submit
      form="form-location"
      :next-loading="submitting"
      :next-disabled="!step.valid"
      @previous="emits('previous')"
    />
  </form>
</template>
