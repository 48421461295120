import type { DogBreed } from '@lyka/bab-api-contracts/src/bab'
import { birthdayToAge } from '@/utils/birthdayConversion'

export enum AgeType {
  Puppy = 'puppy',
  Adult = 'adult',
}

export const getDogAgeType = (dateOfBirth: string, breeds: DogBreed[]): AgeType => {
  const { years, months } = birthdayToAge(dateOfBirth)
  const dogAgeMonths = years * 12 + months

  const adultAgeMonthsMax = breeds.reduce((sum, breed) => sum + breed.adultAgeMonthsMax, 0) / (breeds.length || 1)

  return dogAgeMonths >= adultAgeMonthsMax ? AgeType.Adult : AgeType.Puppy
}
