import { initContract } from '@ts-rest/core'
import z from 'zod'

/* eslint-disable @typescript-eslint/no-redeclare */

const c = initContract()

const ErrorResponse = z.object({
  error: z.string(),
})

const AnimalId = z.string()

const userAccessToken = z.string()

export const HasMany = z.array(z.string().uuid())

export const Gender = {
  Male: 'male',
  Female: 'female',
} as const

export type Gender = (typeof Gender)[keyof typeof Gender]

export const BodyType = {
  Underweight: 'slim',
  Average: 'perfect',
  Overweight: 'chunky',
} as const

export type BodyType = (typeof BodyType)[keyof typeof BodyType]

export const ActivityLevel = {
  High: 'ball_of_energy',
  Medium: 'loves_to_play',
  Low: 'chilled',
} as const

export type ActivityLevel = (typeof ActivityLevel)[keyof typeof ActivityLevel]

export const FussinessLevel = {
  High: 'very_fussy',
  Medium: 'choosy',
  Low: 'eats_anything',
} as const

export type FussinessLevel = (typeof FussinessLevel)[keyof typeof FussinessLevel]

export const MAX_DOG_AGE = 31

export const DogProperties = z.object({
  name: z.string().min(1),
  gender: z.enum([Gender.Male, Gender.Female]),
  activityLevel: z.enum([ActivityLevel.High, ActivityLevel.Medium, ActivityLevel.Low]),
  eatingHabits: z.enum([FussinessLevel.High, FussinessLevel.Medium, FussinessLevel.Low]),
  bodyType: z.enum([BodyType.Underweight, BodyType.Average, BodyType.Overweight]),
  dateOfBirth: z
    .string()
    .date()
    .refine((data) => {
      const today = new Date()
      const birthday = new Date(data).getTime()

      const minBirthday = new Date().setFullYear(today.getFullYear() - MAX_DOG_AGE)

      if (birthday > today.getTime()) {
        return false
      }

      return minBirthday < birthday
    }),
  dateOfBirthIsApproximate: z.boolean(),
  weight: z.coerce.number().multipleOf(0.1).positive().max(99),
  breeds: HasMany.max(2),
  previousFoods: HasMany,
  foodAllergies: HasMany,
  healthIssues: HasMany,
})

export const DogData = DogProperties.partial()

export type DogData = z.infer<typeof DogData>

type Identifiable<T extends object> = T & {
  identifier: string
}

export type DogEntity = Identifiable<DogData>

export type DogBreed = Identifiable<{
  name: string
  adultWeightMaleMin: number
  adultWeightMaleMax: number
  adultWeightFemaleMin: number
  adultWeightFemaleMax: number
  adultAgeMonthsMax: number
}>

export type DogHealthIssue = Identifiable<{
  name: string
}>

export type DogFoodAllergen = Identifiable<{
  name: string
}>

export type DogFoodType = Identifiable<{
  name: string
}>

export type LykaUser = Identifiable<
  Partial<{
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
  }>
>

export const babContract = c.router(
  {
    getDogsForUser: {
      method: 'GET',
      path: '/animals/getDogsForUser',
      summary: 'Get animals for the current user',
      query: z.object({
        userAccessToken,
      }),
      responses: {
        200: c.type<DogEntity[]>(),
        401: ErrorResponse,
        500: ErrorResponse,
      },
    },

    getDog: {
      method: 'GET',
      path: '/animals/getDog',
      summary: 'Get dog by ID',
      query: z.object({
        animalId: AnimalId,
        userAccessToken: userAccessToken.optional(),
      }),
      responses: {
        200: c.type<DogEntity>(),
        404: ErrorResponse,
        500: ErrorResponse,
      },
    },

    createDog: {
      method: 'POST',
      path: '/animals/createDog',
      summary: 'Create a new animal',
      body: z.object({}).optional(),
      responses: {
        201: c.type<DogEntity>(),
        500: ErrorResponse,
      },
    },

    updateDog: {
      method: 'POST',
      path: '/animals/updateDog',
      summary: 'Update an animal',
      body: z.object({
        animalId: AnimalId,
        animal: DogData,
        userAccessToken: userAccessToken.optional(),
      }),
      responses: {
        200: c.type<DogEntity>(),
        401: ErrorResponse,
        422: ErrorResponse,
        404: ErrorResponse,
        500: ErrorResponse,
      },
    },

    getDogBreeds: {
      method: 'GET',
      path: '/animals/getDogBreeds',
      summary: 'Get the list of dog breeds',
      responses: {
        200: c.type<DogBreed[]>(),
        500: ErrorResponse,
      },
    },

    getDogHealthIssues: {
      method: 'GET',
      path: '/animals/getDogHealthIssues',
      summary: 'Get the list of dog health issues',
      responses: {
        200: c.type<DogHealthIssue[]>(),
        500: ErrorResponse,
      },
    },

    getDogFoodAllergens: {
      method: 'GET',
      path: '/animals/getDogFoodAllergens',
      summary: 'Get the list of dog food allergens',
      responses: {
        200: c.type<DogFoodAllergen[]>(),
        500: ErrorResponse,
      },
    },

    getDogFoodTypes: {
      method: 'GET',
      path: '/animals/getDogFoodTypes',
      summary: 'Get the list of dog food types',
      responses: {
        200: c.type<DogFoodType[]>(),
        500: ErrorResponse,
      },
    },

    associateAnimalWithUser: {
      method: 'POST',
      path: '/animals/associateAnimalWithUser',
      summary: 'Associate an animal with the current user based on the auth token',
      body: z.object({
        animalId: AnimalId,
        userAccessToken,
      }),
      responses: {
        200: z.object({
          success: z.boolean(),
        }),
      },
    },

    getSuburbServiceable: {
      method: 'GET',
      path: '/courier/getSuburbServiceable',
      summary: 'Check if a suburb is serviceable',
      query: z.object({
        postcode: z.string().length(4),
        suburb: z.string(),
      }),
      responses: {
        200: z.object({
          serviceable: z.boolean(),
        }),
        404: ErrorResponse,
        500: ErrorResponse,
      },
    },

    getSuburbDeliverySchedule: {
      method: 'GET',
      path: '/courier/getSuburbDeliverySchedule',
      summary: 'Get a suburbs delivery schedule',
      query: z.object({
        postcode: z.string().length(4),
        suburb: z.string(),
      }),
      responses: {
        200: z.object({
          slots: z.array(z.object({})),
        }),
        404: ErrorResponse,
        500: ErrorResponse,
      },
    },

    createGuestUser: {
      method: 'POST',
      path: '/users/createGuestUser',
      summary: 'Create a guest user',
      body: z.object({
        email: z.string().email(),
        firstName: z.string(),
      }),
      responses: {
        201: z.object({
          userId: z.string(),
          userAccessToken: z.string(),
          userRefreshToken: z.string(),
        }),
        400: ErrorResponse,
      },
    },

    getCurrentUser: {
      method: 'GET',
      path: '/users/getCurrentUser',
      summary: 'Get the current user',
      query: z.object({
        userAccessToken,
      }),
      responses: {
        200: c.type<LykaUser>(),
        401: ErrorResponse,
      },
    },
  },

  {
    strictStatusCodes: true,
  },
)
