<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { LykaSpinner } from '@lyka/ui'
import { assetUrl } from '@lyka/utils'
import PreviousSessionModal from './components/PreviousSessionModal.vue'
import TheNotifications from './components/TheNotifications.vue'
import CurrentStep from './components/CurrentStep.vue'
import GettingStarted from './components/GettingStarted.vue'
import { useBeenHereBeforeModal } from './composables/useBeenHereBeforeModal'
import StepError from './components/StepError.vue'
import { useDownForMaintenance } from './composables/useDownForMaintenance'
import { useUserStore } from './stores/user'
import { useAuthStore } from './stores/auth'
import BeenHereBeforeModal from './components/BeenHereBeforeModal.vue'
import { useStateStore } from './stores/state'
import TheHeader from '@/components/TheHeader.vue'
import { getURLParam } from '@/utils/getURLParam'
import { type StepName, useStepsStore } from '@/stores/steps'
import TheConfirmModal from '@/components/TheConfirmModal.vue'
import { useKnownUserExperiments } from '@/composables/useKnownUserExperiments'
import { useAnonymousUserExperiments } from '@/composables/useAnonymousUserExperiments'
import { useDogsStore } from '@/stores/dogs'

const stepsStore = useStepsStore()
const stateStore = useStateStore()

const beenHereBeforeModal = useBeenHereBeforeModal()

const loading = ref(true) // Is true while the app loads its initial data
const error = ref(false)
const hideStepCount = computed(() => !!stepsStore.currentStep.hideSteps)

const previousSessionModalOpened = ref(false)

const gettingStarted = ref(stateStore.isNewSession)

const showGetStartedBeenHereBefore = (): void => {
  beenHereBeforeModal.show()
  gettingStarted.value = false
}

const openPreviousSessionModal = (): void => {
  previousSessionModalOpened.value = true
}

const closePreviousSessionModal = (): void => {
  previousSessionModalOpened.value = false
}

const continuePreviousSessionModal = async (step: StepName): Promise<void> => {
  closePreviousSessionModal()

  loading.value = true

  await stepsStore.goToStep(step)

  loading.value = false
}

const loadData = async (): Promise<void> => {
  await useAuthStore().reauthenticate()

  await Promise.all([useDogsStore().load(), useUserStore().load()])
}

const loadExperiments = async (): Promise<void> => {
  await useAnonymousUserExperiments().load()
  await useKnownUserExperiments().load()
}

const showGettingStarted = computed(() => {
  return gettingStarted.value && stepsStore.isFirstStep && !getURLParam('breed')
})

const downForMaintenance = computed(() => {
  return useDownForMaintenance().isDownForMaintenance.value
})

onMounted(async () => {
  try {
    await Promise.all([
      // Identify the current user with their experiments
      loadExperiments(),

      // Load the BAB data models (breeds, illnesses, etc.)
      loadData(),
    ])

    await useDownForMaintenance().load()

    await stepsStore.initialize()

    if (!stateStore.isNewSession && stepsStore.isFirstStep) {
      openPreviousSessionModal()
    }

    // loadBreedFromURL()

    // Send checkout started events
    // useCheckoutStartedEvents({ isNewSession }).send()
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <div id="root" class="tw-flex tw-flex-col tw-overflow-hidden tw-min-h-screen tw-text-alt">
    <div v-if="loading">
      <div class="tw-flex tw-items-center tw-justify-center tw-h-[35vh]">
        <LykaSpinner />
      </div>
    </div>

    <div v-else-if="downForMaintenance">
      <div class="tw-max-w-screen-xs tw-space-y-4 tw-mx-auto tw-mt-20 tw-px-6 tw-text-center">
        <div>
          <img
            :src="assetUrl('images/maintenance/sleeping.svg')"
            alt="Maintenance"
            class="tw-w-full tw-max-w-xs tw-mx-auto tw-mb-10"
          />
        </div>
        <h1 class="tw-h2">We'll be back soon</h1>
        <p class="tw-lead">Lyka is down for maintenance while we make some upgrades. We'll be back online soon.</p>
      </div>
    </div>

    <template v-else>
      <!-- <template v-if="stepsStore.completed">
        <CheckoutCompleted />
      </template> -->

      <GettingStarted
        v-if="showGettingStarted"
        @dismiss="gettingStarted = false"
        @continue="showGetStartedBeenHereBefore"
      />

      <template v-else>
        <TheHeader />
        <!-- <TheCouponBanner v-if="showCouponBanner" /> -->

        <div id="alert"></div>

        <main class="tw-h-full tw-grow tw-flex tw-flex-col tw-relative tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-grow tw-overflow-auto">
            <div
              class="tw-flex tw-flex-col tw-grow tw-overflow-x-hidden tw-pb-28"
              :class="[
                {
                  'sm:tw-pt-16 tw-pt-6': hideStepCount,
                  'tw-pt-6 sm:tw-pt-8': !hideStepCount,
                },
              ]"
            >
              <!-- <TheSteps v-if="!hideStepCount" /> -->
              <StepError v-if="error" />
              <CurrentStep v-else />
            </div>
          </div>
        </main>

        <PreviousSessionModal
          :open="previousSessionModalOpened"
          @continue="continuePreviousSessionModal"
          @close="closePreviousSessionModal"
        />
      </template>

      <BeenHereBeforeModal :open="beenHereBeforeModal.visible.value" @close="beenHereBeforeModal.hide" />
    </template>

    <div id="stepNav" class="tw-w-full tw-fixed tw-bottom-0 tw-left-0 tw-z-20" />
    <TheNotifications />
    <TheConfirmModal />

    <!-- Used to render into via Teleport -->
    <footer id="app-footer"></footer>
  </div>
</template>

<style>
/* Hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
