<script setup lang="ts">
import { LykaModal } from '@lyka/ui'
import { onMounted } from 'vue'
import { type MealPlanWeight, caloriesFromGrams } from '@/models/MealPlanWeight'
import { type MealPlan } from '@/models/MealPlan'
import { usePlanCaloriesModalShownEvent } from '@/composables/events/segment/usePlanCaloriesModalShownEvent'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const TRIAL_POUCH_SIZE_GRAMS = 125

const trialPouchCalories = caloriesFromGrams(TRIAL_POUCH_SIZE_GRAMS)

const caloriesPerDay = (mealWeight: MealPlanWeight): number => {
  return caloriesFromGrams(mealWeight.servingWeight)
}

const percentOfCalories = (mealWeight: MealPlanWeight): number => {
  return (trialPouchCalories / caloriesPerDay(mealWeight)) * 100
}

onMounted(() => {
  usePlanCaloriesModalShownEvent().send({
    mealPlan: props.mealPlan,
  })
})
</script>

<template>
  <LykaModal open closable @close="emits('close')">
    <div class="tw-space-y-6 tw-font-light">
      <h3 class="tw-h3 tw-text-center">Lyka Trial Box</h3>

      <div class="tw-text-left tw-text-base tw-space-y-4">
        <p>
          Our Trial Box lets you sample Lyka with smaller portions to supplement your dog's current feeding routine
          before transitioning to your Full Bowl Plan.
        </p>

        <div
          v-for="mealWeight in mealWeights"
          :key="mealWeight.key"
          class="tw-flex-col sm:tw-flex-row tw-border-y tw-border-current tw-font-bold tw-flex tw-items-center tw-justify-center tw-gap-3 tw-py-5"
        >
          <div>1 &times; Taster pouch</div>
          <div>=</div>
          <div>{{ percentOfCalories(mealWeight).toFixed(0) }}% of {{ mealWeight.name }}’s daily calories.</div>
        </div>
        <h4 class="tw-font-bold tw-text-lg tw-pt-6">Feeding guide</h4>
        <p>
          We recommend serving <strong class="tw-font-bold">one pouch per day</strong> and supplementing the remaining
          calories with your dog's current food.
        </p>
        <p><srong class="tw-font-bold">We'll also include a full feeding guide in your Trial Box.</srong></p>
        <h4 class="tw-font-bold tw-text-lg tw-pt-6">After trial</h4>
        <p>
          After your trial, your next delivery will include their full daily serving size, which you can adjust anytime.
        </p>
      </div>
    </div>
  </LykaModal>
</template>
