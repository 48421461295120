<script setup lang="ts">
import { computed } from 'vue'
import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import type { DogEntity, MealRecipe } from '@lyka/bab-api-contracts/src/bab'
import RecipesForDogCard from './RecipesForDogCard.vue'
import RecipesForDogTraits from './RecipesForDogTraits.vue'
import RecipesForDogList from './RecipesForDogList.vue'
import TherapeuticConfirmationModal from './RecipesTherapeuticConfirmationModal.vue'
import { useConfirm } from '@/composables/useConfirm'
import { isTherapeuticRecipe, isUnsuitableRecipe } from '@/models/Recipe'
// import { useProductAddedEvent, useProductRemovedEvent } from '@/composables/events/segment/useProductEvents'

const props = withDefaults(
  defineProps<{
    dog: DogEntity
    dogTraits: string[]
    multipleDogs?: boolean
    selectedRecipes?: MealRecipe[]
    suitableRecipes?: MealRecipe[]
    unsuitableRecipes?: MealRecipe[]
    therapeuticRecipes?: MealRecipe[]
    unsuitableTherapeuticRecipes?: MealRecipe[]
  }>(),
  {
    selectedRecipes: () => [],
    suitableRecipes: () => [],
    unsuitableRecipes: () => [],
    unsuitableTherapeuticRecipes: () => [],
  },
)

const emit = defineEmits<{
  (e: 'previous'): void
  (e: 'update', recipes: MealRecipe[]): void
}>()

const addRecipe = async (recipe: MealRecipe): Promise<void> => {
  if (isUnsuitableRecipe(recipe)) {
    const confirmed = await useConfirm().show({
      title: 'Are you sure you want to add this meal?',
      message: `We've excluded this meal based on your dog's specific health profile. Before feeding it to them, we'd recommend checking with an expert.`,
      confirm: 'Yes',
      decline: 'No',
    })

    if (!confirmed) {
      return
    }
  }

  const recipes = new Set<MealRecipe>()

  if (isTherapeuticRecipe(recipe)) {
    const confirmed = await useConfirm().show({
      component: TherapeuticConfirmationModal,
      confirm: 'Add meal',
    })

    if (!confirmed) {
      return
    }
  } else {
    // Remove all therapeutic recipes if a non-therapeutic recipe is added
    for (const recipe of props.selectedRecipes) {
      if (!isTherapeuticRecipe(recipe)) {
        recipes.add(recipe)
      }
    }
  }

  recipes.add(recipe)
  emit('update', Array.from(recipes))

  // useProductAddedEvent({
  //   product: recipe,
  //   type: 'recipe',
  //   recommended: isSuitableRecipe(recipe),
  // }).send()
}

const removeRecipe = (recipe: MealRecipe, recommended: boolean): void => {
  const recipes = new Set<MealRecipe>([...props.selectedRecipes])
  recipes.delete(recipe)
  emit('update', Array.from(recipes))

  // useProductRemovedEvent({
  //   product: recipe,
  //   type: 'recipe',
  //   recommended,
  // }).send()
}

const selectedRecipeIds = computed(() => {
  return props.selectedRecipes.map((recipe) => recipe.identifier)
})

const hasUnsuitableRecipes = computed<boolean>(() => {
  return props.unsuitableRecipes.length > 0
})

const showTherapeuticRecipes = computed<boolean>(() => {
  const { therapeuticRecipes = [], unsuitableTherapeuticRecipes = [] } = props

  return (
    therapeuticRecipes.length + unsuitableTherapeuticRecipes.length > 0 &&
    useFlagsmith().isEnabled('therapeutic-recipes')
  )
})
</script>

<template>
  <div class="tw-max-w-2xl tw-mx-auto tw-px-4 tw-mt-10">
    <div class="tw-space-y-6">
      <div class="tw-space-y-2 tw-text-center">
        <h3 class="tw-font-stylized tw-text-4xl md:tw-text-6xl">
          Review {{ multipleDogs ? `${dog.name}'s` : 'their' }} first delivery
        </h3>

        <p class="tw-text-base md:tw-text-xl">You can easily change their meals for all future deliveries</p>
      </div>

      <RecipesForDogTraits v-if="multipleDogs" :traits="dogTraits" />
    </div>

    <div class="tw-mt-10 md:tw-mt-12 tw-space-y-10 md:tw-space-y-12">
      <RecipesForDogList recommended :heading="hasUnsuitableRecipes" :name="dog.name">
        <!-- Recommended Recipes -->
        <RecipesForDogCard
          v-for="recipe in suitableRecipes"
          :key="recipe.identifier"
          :dog="dog"
          :selected="selectedRecipeIds.includes(recipe.identifier)"
          :recipe="recipe"
          recommended
          @add="addRecipe"
          @remove="removeRecipe"
        />
      </RecipesForDogList>

      <!-- Unsuitable Recipes -->
      <RecipesForDogList
        v-if="unsuitableRecipes.length"
        :recommended="false"
        :heading="hasUnsuitableRecipes"
        :name="dog.name"
      >
        <RecipesForDogCard
          v-for="recipe in unsuitableRecipes"
          :key="recipe.identifier"
          :dog="dog"
          :selected="selectedRecipeIds.includes(recipe.identifier)"
          :recipe="recipe"
          not-recommended
          @add="addRecipe"
          @remove="removeRecipe"
        />
      </RecipesForDogList>

      <!-- Therapeutic Recipes -->
      <div v-if="showTherapeuticRecipes">
        <div class="tw-mb-10 tw-space-y-2 tw-text-center">
          <h3 class="tw-font-stylized tw-text-4xl md:tw-text-6xl">Therapeutic</h3>

          <p class="tw-text-base">Meal plan options designed for specific health conditions, as guided by Vets.</p>
        </div>

        <RecipesForDogList v-if="therapeuticRecipes?.length" :name="dog.name">
          <RecipesForDogCard
            v-for="recipe in therapeuticRecipes"
            :key="recipe.identifier"
            :dog="dog"
            :selected="selectedRecipeIds.includes(recipe.identifier)"
            :recipe="recipe"
            recommended
            therapeutic
            @add="addRecipe"
            @remove="removeRecipe"
          />
        </RecipesForDogList>

        <RecipesForDogList
          v-if="unsuitableTherapeuticRecipes.length"
          :recommended="false"
          :name="dog.name"
          :heading="hasUnsuitableRecipes"
        >
          <RecipesForDogCard
            v-for="recipe in unsuitableTherapeuticRecipes"
            :key="recipe.identifier"
            :dog="dog"
            :selected="selectedRecipeIds.includes(recipe.identifier)"
            :recipe="recipe"
            therapeutic
            @add="addRecipe"
            @remove="removeRecipe"
          />
        </RecipesForDogList>
      </div>
    </div>
  </div>
</template>
