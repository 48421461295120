import {
  experimentsFromURL,
  filterForcedExperiments,
  useExperiments,
} from '@lyka/vue-common/composables/useExperiments'
import { experimentsFromFlags, useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { ref } from 'vue'
import { useExperimentEvents, useExperimentForcedEvents } from './events/segment/useExperimentEvents'
import { useUserStore } from '@/stores/user'

/**
 * Get the SHA256 hash of the user's email address to act as a unique identifier.
 */
const getUserEmailHash = async (): Promise<string | undefined> => {
  const userStore = useUserStore()

  const user = await userStore.loadCurrentUser()

  if (!user?.email) {
    return undefined
  }

  // Convert the email to a SHA256 hash
  const utf8 = new TextEncoder().encode(user.email)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

const loaded = ref(false)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useKnownUserExperiments = () => {
  const load = async (): Promise<void> => {
    if (loaded.value) {
      return
    }

    // Generate a hash of the user's email to create a unique identifier
    const userId = await getUserEmailHash()

    // If we have a user ID then identify them with the flagsmith service to seed their "known" user experiments
    if (userId) {
      const userFlags = await useFlagsmith().identify(userId)

      if (!userFlags) {
        return
      }

      const userExperiments = experimentsFromFlags(userFlags, 'known')

      const forcedExperiments = filterForcedExperiments(experimentsFromURL(location.href), userExperiments)

      useExperiments().setExperiments({ ...userExperiments, ...forcedExperiments })

      // Fire the events for the experiments
      useExperimentEvents(userExperiments).send()

      // Fire the events for the forced experiments
      useExperimentForcedEvents(forcedExperiments).send()

      loaded.value = true
    }
  }

  return {
    load,
  }
}
