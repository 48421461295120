<script setup lang="ts">
import { LykaButton, LykaFieldList, LykaSelect } from '@lyka/ui'
import { computed } from 'vue'
import type { DogBreed, DogEntity } from '@lyka/bab-api-contracts/src/bab'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import StepHeading from '@/components/StepHeading.vue'

const props = defineProps<{
  dog: DogEntity
  breeds: DogBreed[]
}>()

const emits = defineEmits<{
  (e: 'update', dog: DogEntity, breeds: string[]): void
}>()

const breedOptions = computed(() => {
  return props.breeds.map((breed) => {
    return {
      value: breed.slug,
      text: breed.name,
    }
  })
})

const selected = computed(() => {
  return props.dog.breeds ?? []
})

const dogPrimaryBreed = computed({
  get() {
    return selected.value[0]
  },
  set(breed) {
    const [, secondary] = selected.value
    const breeds = [breed, secondary].filter(Boolean) as string[]

    emits('update', props.dog, breeds)
  },
})

const dogSecondaryBreed = computed({
  get() {
    return selected.value[1]
  },
  set(breed) {
    const [primary] = selected.value
    const breeds = [primary, breed].filter(Boolean) as string[]

    emits('update', props.dog, breeds)
  },
})

const showSecondaryBreed = computed<boolean>(() => {
  return !!dogPrimaryBreed.value
})

const removeSecondaryBreed = (): void => {
  dogSecondaryBreed.value = undefined
}

// const dogSecondaryBreed = computed({
//   get() {
//     return props.modelValue.secondary
//   },
//   set(value) {
//     emits('update:modelValue', {
//       ...props.modelValue,
//       // type: value ? BreedType.Mix : BreedType.Pure,
//       secondary: value,
//     })
//   },
// })

// const dogUnknownBreed = computed({
//   get() {
//     return props.modelValue.primary
//   },
//   set(value) {
//     emits('update:modelValue', {
//       ...props.modelValue,
//       type: BreedType.Unknown,
//       primary: value,
//     })
//   },
// })

// const removeSecondaryBreed = (): void => {
//   emits('update:modelValue', {
//     ...props.modelValue,
//     secondary: null,
//     type: BreedType.Pure,
//   })
// }

// const dogIsUnknownBreed = computed({
//   get() {
//     return props.modelValue.type === BreedType.Unknown
//   },
//   set(value) {
//     emits('update:modelValue', {
//       type: value ? BreedType.Unknown : BreedType.Pure,
//       primary: null,
//       secondary: null,
//     })
//   },
// })

// const dogIsDalmatianBreed = computed(() => {
//   if (!dogPrimaryBreed.value) {
//     return false
//   }

//   // check if primary breed is a dalamatian type breed
//   if (DALMATION_DOG_BREED_IDS.includes(dogPrimaryBreed!.value)) {
//     return true
//   }

//   // check if secondary breed is a dalamatian type breed
//   if (dogSecondaryBreed.value && DALMATION_DOG_BREED_IDS.includes(dogSecondaryBreed.value)) {
//     return true
//   }

//   return false
// })

// const dogName = computed(() => {
//   return dogsStore.dogName(props.index)
// })

// const getBreedWeightMin = (breed: Breed): number => {
//   if (props.gender === Gender.Girl) {
//     return breed.adultWeightFemaleMin
//   }

//   return breed.adultWeightMaleMin
// }

// const getBreedWeightMax = (breed: Breed): number => {
//   if (props.gender === Gender.Girl) {
//     return breed.adultWeightFemaleMax
//   }

//   return breed.adultWeightMaleMax
// }

// const getBreedWeightDescription = (breed: Breed): string => {
//   const min = getBreedWeightMin(breed)
//   const max = getBreedWeightMax(breed)

//   switch (breed.maleSizeClassification) {
//     case 'teacup': {
//       return `Up to ${max}kg fully grown`
//     }
//     case 'giant': {
//       return `${min}kg+ fully grown`
//     }
//     default: {
//       return `${min}-${max}kg fully grown`
//     }
//   }
// }
// const getBreedSizeClassification = (breed: Breed): string => {
//   return capitalize(breed.maleSizeClassification)
// }

// const getBreedExamples = (breed: Breed): string => {
//   return breed.breedExamples ? `${breed.breedExamples}` : ''
// }
</script>

<template>
  <div>
    <StepHeading> What breed is {{ dog.name }}? </StepHeading>

    <LykaFieldList>
      <LykaSelect
        v-model="dogPrimaryBreed"
        :name="`breed-primary-${dog.identifier}`"
        label="Select breed"
        hide-label
        placeholder="Select breed"
        search
        :options="breedOptions"
      >
        <template #noResults>
          <strong>No matches found.</strong> For breeds not listed, please select option “unknown”
        </template>
      </LykaSelect>

      <template v-if="showSecondaryBreed">
        <LykaSelect
          v-model="dogSecondaryBreed"
          :name="`breed-secondary-${dog.identifier}`"
          label="Select breed"
          hide-label
          placeholder="Select breed"
          search
          :options="breedOptions"
        />
        <LykaButton block variant="secondary" @click="removeSecondaryBreed"> Remove cross-breed </LykaButton>
      </template>

      <LykaButton v-else :id="`breed-secondary-add-${dog.identifier}`" block variant="secondary">
        <template #iconLeft>
          <PlusIcon />
        </template>
        Add a cross-breed
      </LykaButton>
    </LykaFieldList>

    <!-- TODO -->
    <!-- <LykaMessage v-if="dogIsDalmatianBreed" class="tw-mt-5 tw-text-left tw-p-">
      <div class="tw-flex tw-flex-row tw-py-6 tw-px-4">
        <div class="tw-mr-4 tw-mt-1">
          <ExclamationMarkIcon />
        </div>
        <div class="tw-leading-6">
          <strong>Warning: Dietary Consideration for Dalmatians with SLC2 Gene</strong>
          <div class="tw-mt-2 tw-leading-5">
            All meals except perhaps Turkey may <strong>not</strong> be suitable for Dalmatians with the SLC2 gene. We
            recommend consulting your vet before feeding Lyka.
          </div>
        </div>
      </div>
    </LykaMessage> -->

    <!-- <LykaRadio
      v-model="dogIsUnknownBreed"
      :name="`breed-unknown-${index}`"
      class="tw-mt-14 sm:tw-mt-20"
      checkbox
      type="button"
      block
      label="I don’t know the breed"
      :input-value="true"
    />

    <LykaTransitionSlideIn>
      <div v-if="dogIsUnknownBreed" class="tw-mt-6">
        <h3 class="tw-mb-6 tw-h3">Select the best size match for {{ dogName }}</h3>

        <div class="tw-space-y-3 tw-justify-center tw-grid tw-grid-cols-1 tw-justify-items-center">
          <OptionTile
            v-for="breed in unknownBreeds"
            :key="breed.id"
            v-model="dogUnknownBreed"
            input-type="radio"
            :input-value="breed.id"
            :title="getBreedSizeClassification(breed)"
          >
            <template #description>
              <span class="tw-space-y-2 tw-block">
                <span class="tw-block">{{ getBreedWeightDescription(breed) }}</span>
                <span class="tw-block">e.g. {{ getBreedExamples(breed) }}</span>
              </span>
            </template>
          </OptionTile>
        </div>
      </div>
    </LykaTransitionSlideIn> -->
  </div>
</template>
