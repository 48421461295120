import { defineStore } from 'pinia'
import { type Ref, computed, ref, toRaw, watch, watchEffect } from 'vue'
import { useNameStep } from '@/steps/name'
import type { Step } from '@/steps/Step'
import { instantiateSteps, registeredSteps } from '@/steps/stepRegistry'
import { useBrowserHistory } from '@/composables/useBrowserHistory'
import { useTitle } from '@/composables/useTitle'
import { useWindowPosition } from '@/composables/useWindowPosition'

export enum StepName {
  Name = 'name',
  Location = 'location',
  UnservicedLocation = 'unserviced-location',
  Gender = 'gender',
  Breed = 'breed',
  Age = 'age',
  Weight = 'weight',
  BodyShape = 'shape',
  Activity = 'activity',
  Fussiness = 'fussiness',
  PreviouslyFed = 'previously-fed',
  Allergies = 'allergies',
  // @deprecated
  HasIllnesses = 'illness',
  // @deprecated
  Illnesses = 'illnesses',
  HealthIssues = 'illnesses',
  ContactDetails = 'contact',
  LoadingPlan = 'loading',
  NoRecipes = 'no-recipes',
  Recipes = 'recipes',
  Plan = 'plan',
  Treats = 'treats',
  Checkout = 'checkout',
  CheckoutFreeTreats = 'free-treats',
}

export const STEP_NAMES = Object.values(StepName)

export enum StepChangeDirection {
  Forward = 'forward',
  Backward = 'backward',
}

export const useStepsStore = defineStore('steps', () => {
  const FIRST_STEP = useNameStep

  // Instantiate the steps when the store is created so that they can begin
  // observing events. We have to do this within the store because we need to
  // wait for Pinia to load before we can create the Step instances:
  // https://pinia.vuejs.org/core-concepts/outside-component-usage.html
  instantiateSteps()

  const stepList = registeredSteps
  const currentStep = ref<Step>(FIRST_STEP()) as unknown as Ref<Step>
  const completed = ref(false)
  const browserHistory = useBrowserHistory()
  const title = useTitle()
  const windowPosition = useWindowPosition()

  // The direction of the last step change
  const direction = ref(StepChangeDirection.Forward)
  const currentStepName = computed(() => currentStep.value.name)

  const onStepChange = (newStep: Step, oldStep: Step): void => {
    const backward = oldStep.prev()?.isSameStep(newStep)

    direction.value = backward ? StepChangeDirection.Backward : StepChangeDirection.Forward
    windowPosition.scrollToTop()
  }

  const updateStep = (step: Step, changeDirection = StepChangeDirection.Forward, addToHistory = true): void => {
    currentStep.value = step
    direction.value = changeDirection

    if (addToHistory) {
      browserHistory.pushStepToHistory(currentStep.value)
    }
  }

  watch(() => currentStep.value, onStepChange)

  const nextStep = (): void => {
    if (!currentStep.value.getValid()) {
      return
    }

    const next = currentStep.value.next()

    if (next) {
      currentStep.value.onComplete?.()

      updateStep(next)
    } else {
      completed.value = true
    }
  }

  const previousStep = (): void => {
    const previous = currentStep.value.prev()

    if (previous) {
      updateStep(previous, StepChangeDirection.Backward)
    }
  }

  const getFirstStep = (): Step => {
    return FIRST_STEP()
  }

  const goToFirstStep = (): void => {
    updateStep(getFirstStep(), StepChangeDirection.Backward)
  }

  const getLastStep = (): Step => {
    let current: Step = currentStep.value

    while (current.getValid() && current.next()) {
      current = current.next()!
    }

    return current
  }

  const goToLastStep = (): void => {
    updateStep(getLastStep())
  }

  const saveStep = (data: object): void => {
    currentStep.value.update(toRaw(data))
  }

  const submitStep = (data: object): void => {
    saveStep(data)
    nextStep()
  }

  const goToStep = (stepName: StepName, addToHistory = true): boolean => {
    let current: Step | undefined = FIRST_STEP()
    let match: Step | undefined

    do {
      if (current.name === stepName) {
        match = current
      }

      current = current.getValid() ? current.next() : undefined
    } while (!match && current)

    if (match) {
      updateStep(match, undefined, addToHistory)
      return true
    }

    return false
  }

  const isStepName = (stepName: unknown): stepName is StepName => {
    return STEP_NAMES.includes(stepName as StepName)
  }

  const isFirstStep = computed(() => {
    return currentStepName.value === FIRST_STEP().name
  })

  const initialize = (initialStepName?: StepName): void => {
    if (initialStepName) {
      goToStep(initialStepName)
    }

    // When the store loads push the initial step name into the history
    history.replaceState({ step: currentStepName.value }, '')
  }

  // Listen for history state changes. When the history changes load that step
  addEventListener('popstate', ({ state }) => {
    if (state?.step) {
      // Go to the history step but don't trigger another history entry
      // otherwise we'll end up adding a duplicate history record
      goToStep(state.step as StepName, false)
    }
  })

  // Update the page title to match the current step
  watchEffect(() => title.update(currentStep.value.title))

  // When the current step is modified trigger the `onStart` method of that step
  const trackStepStart = (step: Step): void => step.onStart()
  watchEffect(() => trackStepStart(currentStep.value))

  return {
    isFirstStep,
    direction,
    steps: stepList,
    currentStep,
    currentStepName,
    completed,
    previousStep,
    saveStep,
    submitStep,
    nextStep,
    getFirstStep,
    goToFirstStep,
    getLastStep,
    goToLastStep,
    goToStep,
    isStepName,
    initialize,
  }
})
