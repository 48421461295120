<script setup lang="ts">
import { computed, ref } from 'vue'
import { assetUrl } from '@lyka/utils'
import { LykaCheckbox } from '@lyka/ui'
import type { DogEntity, MealRecipe } from '@lyka/bab-api-contracts/src/bab'
import CrossIcon from '@/components/icons/CrossIcon.vue'
// import { useRecipeDetailsViewedEvent } from '@/composables/events/segment/useRecipeDetailsViewedEvent'
import { RecipeName, getRecipeCallout } from '@/models/Recipe'

const props = defineProps<{
  dog: DogEntity
  selected?: boolean
  recommended?: boolean
  recipe: MealRecipe
  therapeutic?: boolean
}>()

const emit = defineEmits<{
  (e: 'add', recipe: MealRecipe, recommended: boolean, exclusive: boolean): void
  (e: 'remove', recipe: MealRecipe, recommended: boolean): void
}>()

const toggle = async (): Promise<void> => {
  const selected = !props.selected

  if (selected) {
    emit('add', props.recipe, props.recommended, props.therapeutic)
  } else {
    emit('remove', props.recipe, props.recommended)
  }
}

const bowlImageUrl = computed(() => {
  return assetUrl(`images/recipes/${props.recipe.key}/bowl.webp`)
})

const icon = computed(() => {
  return assetUrl(`images/recipes/${props.recipe.key}/icon.svg`)
})

const nameTitle = computed<string>(() => {
  return getRecipeCallout(props.recipe.key)
})

const notRecommendedForTransitioning = computed<boolean>(() => {
  return !props.recommended && props.recipe.key === RecipeName.Fish
})

const isModalOpen = ref(false)

// const openModal = (): void => {
//   isModalOpen.value = true

//   useRecipeDetailsViewedEvent(props.recipe).send()
// }

const closeModal = (): void => {
  isModalOpen.value = false
}

const id = computed(() => `recipe-${props.recipe.name}`)
</script>

<template>
  <div class="first:tw-rounded-t-3xl last:tw-rounded-b-3xl tw-bg-white tw-max-w-screen-sm">
    <div :id="id" class="tw-flex tw-flex-col tw-cursor-pointer tw-select-none" @click="toggle">
      <div class="tw-p-6 md:tw-p-8 lg:tw-py-10 lg:tw-px-14">
        <div class="tw-grid tw-grow tw-gap-x-4 tw-items-center tw-relative recipe-columns">
          <div class="tw-pointer-events-none tw-mr-2">
            <LykaCheckbox size="lg" contrast :model-value="selected" />
          </div>

          <div class="tw-space-y-3 tw-items-start">
            <div>
              <div class="tw-text-xs sm:tw-text-sm md:tw-text-base tw-font-light tw-text-green">{{ nameTitle }}</div>
              <h4 class="tw-text-lg sm:tw-text-xl md:tw-text-2xl lg:tw-text-3xl">{{ recipe.name }}</h4>
            </div>

            <p v-if="recipe.description" class="tw-text-xs sm:tw-text-sm md:tw-text-base tw-font-light tw-grow">
              {{ recipe.description }}
            </p>
          </div>

          <div class="tw-hidden -tw-ml-2 3xs:tw-block xxs:tw-ml-0 tw-shrink-0 tw-relative">
            <img
              class="tw-transition-all tw-w-24 sm:tw-w-36 md:tw-w-44 tw-aspect-[180/130] tw-object-cover"
              :class="{
                'tw-grayscale': !recommended,
              }"
              :src="bowlImageUrl"
            />

            <img
              :src="icon"
              class="tw-absolute tw-right-0 tw-bottom-0 tw-w-8 tw-aspect-square sm:tw-w-10 md:tw-w-12"
              :alt="recipe.name"
            />
          </div>

          <div v-if="!recommended" class="tw-flex tw-gap-2 tw-mt-6 recipe-warning tw-items-baseline">
            <CrossIcon v-if="recipe.reason || notRecommendedForTransitioning" class="tw-w-3 tw-h-3 tw-shrink-0" />

            <span v-if="recipe.reason" class="tw-text-xs sm:tw-text-sm md:tw-text-base">
              Not recommended due to <b>{{ recipe.reason }}</b>
            </span>

            <span v-else-if="notRecommendedForTransitioning" class="tw-text-xs sm:tw-text-sm md:tw-text-base"
              >Best introduced after transitioning</span
            >
          </div>
          <!-- <button
            type="button"
            aria-label="More information"
            title="More information"
            class="tw-transition-all tw-absolute tw-right-0 tw-top-0 hover:tw-text-green tw-text-3xl hover:tw-scale-125 focus:tw-text-green tw-outline-none"
            @click.stop="openModal"
          >
            <InfoIcon />
          </button> -->
        </div>
      </div>
    </div>

    <!-- <RecipesForDogCardModal :open="isModalOpen" :recipe="recipe" @close="closeModal" /> -->
  </div>
</template>

<style scoped>
.recipe-columns {
  grid-template-areas: 'checkbox details image' 'checkbox warning warning';
  grid-template-columns: auto 1fr auto;
}

.recipe-warning {
  grid-area: warning;
}
</style>
