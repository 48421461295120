<script setup lang="ts">
import PlanInfoCaloriesModal from './PlanInfoCaloriesModal.vue'
import PlanInfoTrialModal from './PlanInfoTrialModal.vue'
import { type MealPlanWeight } from '@/models/MealPlanWeight'
import { type MealPlan } from '@/models/MealPlan'

defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()
</script>

<template>
  <PlanInfoTrialModal
    v-if="mealPlan.type === 'trial'"
    :meal-plan="mealPlan"
    :meal-weights="mealWeights"
    @close="emits('close')"
  />
  <PlanInfoCaloriesModal v-else :meal-plan="mealPlan" :meal-weights="mealWeights" @close="emits('close')" />
</template>
