<script setup lang="ts">
import { computed } from 'vue'
import type { FeedingPlan } from '@lyka/bab-api-contracts/src/bab'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  mealPlan: FeedingPlan
}>()

const totalPrice = computed(() => {
  return props.mealPlan.price || 0
})

const primaryPrice = computed(() => {
  const perDayPrice = (props.mealPlan.price || 0) / (props.mealPlan.deliveryFrequencyWeeks * 7)

  return `${formatMoney(perDayPrice)}/day`
})

const secondaryPrice = computed<string | undefined>(() => {
  return `${formatMoney(totalPrice.value)} total`
})
</script>

<template>
  <div class="price">
    <div class="price-primary" v-html="primaryPrice"></div>
    <div v-if="secondaryPrice" v-html="secondaryPrice"></div>
  </div>
</template>

<style lang="postcss">
.price {
  @apply tw-flex tw-justify-between tw-items-center tw-gap-2 tw-whitespace-nowrap;
  @apply md:tw-flex-col;
}

.price-primary {
  @apply tw-text-lg tw-font-stylized tw-leading-tighter tw-align-baseline;
  @apply sm:tw-text-3xl;
  @apply lg:tw-text-5xl;
}

.price-secondary {
  @apply tw-text-sm;
}
</style>
