<script setup lang="ts">
import { computed } from 'vue'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import StepNavigation from '../StepNavigation.vue'
import AllergiesListDog from './allergies/AllergiesListDog.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { AllergiesStep } from '@/steps/phoenix/allergies'

const props = defineProps<{
  step: AllergiesStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const disabled = computed(() => !props.step.valid.value || props.step.busy.value)

const updateFoodAllergies = (dog: DogEntity, allergies: NonNullable<(typeof dog)['foodAllergies']>): void => {
  const result = DogProperties.shape.foodAllergies.safeParse(allergies)

  if (!result.success) {
    return
  }

  props.step.updateFoodAllergies(dog, result.data)
}

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('next')
}
</script>

<template>
  <StepsContainer>
    <form :id="step.name" v-submit class="tw-space-y-8" @submit.prevent="submit">
      <AllergiesListDog
        v-for="(dog, index) in step.dogs"
        :key="dog.identifier"
        :allergens="step.foodAllergens"
        :dog-index="index"
        :dog="dog"
        @update="(allergies) => updateFoodAllergies(dog, allergies)"
      />

      <StepNavigation submit :next-disabled="disabled" :form="step.name" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
