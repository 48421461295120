<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import StepLoading from './StepLoading.vue'

const props = defineProps<{
  step: {
    load?: () => Promise<void>
  }
}>()

const loading = ref(false)

watchEffect(async () => {
  if ('load' in props.step) {
    loading.value = true

    try {
      await props.step.load?.()
    } finally {
      loading.value = false
    }
  } else {
    loading.value = false
  }
})
</script>

<template>
  <div>
    <StepLoading v-if="loading" />
    <template v-else>
      <slot />
    </template>
  </div>
</template>
