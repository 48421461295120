<script setup lang="ts">
import { assetUrl } from '@lyka/utils'
import { ActivityLevel } from '@lyka/bab-api-contracts/src/bab'
import type { DogEntity } from '@lyka/bab-api-contracts/src/bab'
import OptionGroup from '../common/OptionGroup.vue'
import OptionTile from '../common/OptionTile.vue'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { ActivityStep } from '@/steps/phoenix/activity'

const props = defineProps<{
  step: ActivityStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const getActivityLevelImageURL = (activityLevel: ActivityLevel): string => {
  return assetUrl(`images/steps/activity/${activityLevel}.svg`)
}

const getActivityLevelTitle = (activityLevel: ActivityLevel): string => {
  switch (activityLevel) {
    case ActivityLevel.Low: {
      return 'Chilled out'
    }
    case ActivityLevel.Medium: {
      return 'Loves to play'
    }
    case ActivityLevel.High: {
      return 'Ball of energy'
    }
    default: {
      return ''
    }
  }
}

const getActivityLevelDescription = (activityLevel: ActivityLevel): string => {
  switch (activityLevel) {
    case ActivityLevel.Low: {
      return 'Mellow, loves sleep.'
    }
    case ActivityLevel.Medium: {
      return 'Energetic, small walks.'
    }
    case ActivityLevel.High: {
      return 'Athletic, long walks & runs'
    }
    default: {
      return ''
    }
  }
}

const getActivityLevelDuration = (activityLevel: ActivityLevel): string => {
  switch (activityLevel) {
    case ActivityLevel.Low: {
      return '<30 minutes exercise'
    }
    case ActivityLevel.Medium: {
      return '30-90 minutes exercise'
    }
    case ActivityLevel.High: {
      return '>90 minutes exercise'
    }
    default: {
      return ''
    }
  }
}

const updateActivityLevel = (dog: DogEntity, activityLevel: ActivityLevel): void => {
  props.step.updateActivityLevel(dog, activityLevel)
}

const submit = (): void => {
  emits('next')
}
</script>

<template>
  <StepsContainer size="lg">
    <form :id="step.name" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in step.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading>How active is {{ dog.name }} each day?</StepHeading>

        <OptionGroup>
          <OptionTile
            v-for="activityLevel in ActivityLevel"
            :key="activityLevel"
            :model-value="dog.activityLevel"
            input-type="radio"
            :input-value="activityLevel"
            :title="getActivityLevelTitle(activityLevel)"
            @update:model-value="(value) => updateActivityLevel(dog, value)"
          >
            <template #icon>
              <img :src="getActivityLevelImageURL(activityLevel)" />
            </template>
            <template #description>
              <p>{{ getActivityLevelDescription(activityLevel) }}</p>
              <p class="tw-opacity-60">
                {{ getActivityLevelDuration(activityLevel) }}
              </p>
            </template>
          </OptionTile>
        </OptionGroup>
      </div>

      <StepNavigation submit :next-disabled="!step.valid" :form="step.name" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
