import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, Step } from './Step'
import { useLocationStep } from './location'
import { useBreedStep } from './breed'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { Gender } from '@/models/Dog'
import { type DogData, useDogsStore } from '@/stores/dogs'
import { GTMEventAction, useGTM } from '@/composables/useGTM'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        gender: z.nativeEnum(Gender),
      }),
    )
    .min(1),
})

export interface GenderStepData {
  dogs: {
    gender: Gender | null
  }[]
}

const DEFAULT_VALUE = null

class GenderStep extends Step<GenderStepData> implements DogStep {
  name = StepName.Gender
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Gender'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
    this.dogsStore.on('loaded', this.loadDog.bind(this))
  }

  addDog({ gender = DEFAULT_VALUE }: DogData = {}): void {
    this.data.dogs.push({ gender })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { gender: DEFAULT_VALUE }
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      if (dog.gender) {
        useGTM().sendAction(GTMEventAction.PROVIDED_GENDER, dog.gender)
      }
    })

    useSegment().track('Gender Completed', { dogs: this.data.dogs.map(({ gender }) => ({ gender: gender! })) })
  }

  initialState(): GenderStepData {
    return {
      dogs: [],
    }
  }

  validate() {
    // TODO
  }

  next(): Step {
    return useBreedStep()
  }

  prev(): Step {
    return useLocationStep()
  }
}

export const useGenderStep = registerStep(GenderStep)
