import z from 'zod'
import { computed } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useAllergiesStep } from './allergies'
import { useGuestUserStep } from './user'
import { useRecipesStep } from './recipes'
import { StepName } from '@/stores/steps'
import { useUserStore } from '@/stores/user'
import { useDogsStore } from '@/stores/dogs'

const StepSchema = z
  .array(
    z.object({
      healthIssues: DogProperties.shape.healthIssues,
    }),
  )
  .min(1)

type HealthIssues = z.infer<typeof DogProperties.shape.foodAllergies>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineHealthIssuesStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()
  const userStore = useUserStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useAllergiesStep()
  }

  const getNextStep = (): IStep | undefined => {
    if (userStore.currentUser) {
      return useRecipesStep()
    }

    return useGuestUserStep()
  }

  const updateHealthIssues = async (dog: DogEntity, healthIssues: HealthIssues): Promise<void> => {
    await dogsStore.updateDog(dog, { healthIssues })
  }

  return {
    name: StepName.HealthIssues,
    busy,
    valid,
    dogs: dogsStore.dogs,
    healthIssues: dogsStore.healthIssues,
    updateHealthIssues,
    getPreviousStep,
    getNextStep,
  }
}

export type HealthIssuesStep = ReturnType<typeof defineHealthIssuesStep>

export const useHealthIssuesStep = registerStep(defineHealthIssuesStep)
