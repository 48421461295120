<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    heading?: string
    size?: 'xs' | 'sm' | 'md' | 'lg'
    center?: boolean
    padding?: boolean
  }>(),
  {
    size: 'sm',
    center: true,
    padding: true,
  },
)
const containerWidth = computed(() => {
  switch (props.size) {
    case 'lg': {
      return 'tw-max-w-7xl'
    }
    case 'md': {
      return 'tw-max-w-3xl'
    }
    case 'sm': {
      return 'tw-max-w-lg'
    }
    default: {
      return 'tw-max-w-md'
    }
  }
})
</script>

<template>
  <div :class="{ 'tw-px-4 sm:tw-px-6': padding }">
    <div class="tw-mx-auto tw-w-full" :class="containerWidth">
      <slot />
    </div>
  </div>
</template>
