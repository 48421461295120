import type { IStep, IStepConstructor } from './step'

export type StepInstantiator = () => IStep

export const registeredSteps = new Set<StepInstantiator>()

export const instantiateSteps = (): void => {
  registeredSteps.forEach((instantiator) => {
    instantiator()
  })
}

export const registerStep = (defineStep: IStepConstructor): StepInstantiator => {
  let instance: IStep

  const instantiator = (): IStep => {
    instance ??= defineStep()

    return instance
  }

  registeredSteps.add(instantiator)

  return instantiator
}
