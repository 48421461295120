export enum MealPlanType {
  Full = 'full',
  Half = 'half',
  Starter = 'starter',
  Trial = 'trial',
  Trial7x200g = 'trial-7x200g',
  Trial14x200g = 'trial-14x200g',
}

export interface MealPlan {
  type: MealPlanType
  frequency: number
  priceDaily: number
  priceWeekly: number
  totalPrice: number
  mealPrice: number
  hidden?: boolean
}

export const TRIAL_PLAN_POUCH_SIZE_GRAMS = 125
export const TRIAL_PLAN_POUCH_QUANTITY = 14

export const getMealPlanName = (type: MealPlanType): string => {
  switch (type) {
    case MealPlanType.Full:
      return 'Full bowl plan'
    case MealPlanType.Half:
      return 'Half bowl plan'
    case MealPlanType.Starter:
      return 'Starter box'
    case MealPlanType.Trial:
      return 'Trial box'
    default:
      return ''
  }
}

export const getMealPlanTypeById = (id: number): MealPlanType | undefined => {
  switch (id) {
    case 1: {
      return MealPlanType.Full
    }
    case 2: {
      return MealPlanType.Half
    }
    case 3: {
      return MealPlanType.Starter
    }
  }

  return undefined
}
