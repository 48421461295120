import z from 'zod'
import { computed } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useBodyShapeStep } from './bodyShape'
import { useFussinessStep } from './fussiness'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/phoenix/dogs'

const StepSchema = z
  .array(
    z.object({
      activityLevel: DogProperties.shape.activityLevel,
    }),
  )
  .min(1)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineActivityStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useBodyShapeStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useFussinessStep()
  }

  const updateActivityLevel = async (dog: DogEntity, activityLevel: DogEntity['activityLevel']): Promise<void> => {
    await dogsStore.updateDog(dog, { activityLevel })
  }

  return {
    name: StepName.Activity,
    busy,
    valid,
    dogs: dogsStore.dogs,
    allergens: dogsStore.foodAllergens,
    updateActivityLevel,
    getPreviousStep,
    getNextStep,
  }
}

export type ActivityStep = ReturnType<typeof defineActivityStep>

export const useActivityStep = registerStep(defineActivityStep)
