<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl } from '@lyka/utils'
import type { FeedingPlan } from '@lyka/bab-api-contracts/src/bab'
import PlanCardPrice from './PlanCardPrice.vue'
import { MealPlanType, TRIAL_PLAN_POUCH_QUANTITY, TRIAL_PLAN_POUCH_SIZE_GRAMS } from '@/models/MealPlan'

import StarsIcon from '@/components/icons/StarsIcon.vue'

const props = defineProps<{
  selected: boolean
  mealPlan: FeedingPlan
}>()

const emits = defineEmits<{
  (e: 'select', plan: FeedingPlan): void
  (e: 'info', plan: FeedingPlan): void
}>()

const image = computed<string>(() => {
  return assetUrl(`images/steps/plans/bowls/${props.mealPlan.feedingPlanType}.webp`)
})

const elementId = computed<string>(() => {
  return `plan-${props.mealPlan.feedingPlanType.replace(/[^a-z0-9]+/g, '-').toLowerCase()}`
})

const title = computed<string>(() => {
  switch (props.mealPlan.feedingPlanType) {
    case MealPlanType.Full:
      return 'Full Bowl Plan'
    case MealPlanType.Half:
      return 'Half Bowl Plan'
    case MealPlanType.Starter:
      return 'Starter Box'
    case MealPlanType.Trial:
      return 'Trial Box'
    default:
      return ''
  }
})

const description = computed<string>(() => {
  switch (props.mealPlan.feedingPlanType) {
    case MealPlanType.Full:
      return 'Complete and balanced diet'
    case MealPlanType.Half:
      return 'Mix Lyka with other food'
    case MealPlanType.Starter:
      return 'Taste test with a smaller start'
    case MealPlanType.Trial:
      return 'Start with a small amount of Lyka to taste test over 2 weeks'
    default:
      return ''
  }
})

const deliveryDescription = computed<string | undefined>(() => {
  const { feedingPlanType, deliveryFrequencyWeeks } = props.mealPlan

  switch (feedingPlanType) {
    case MealPlanType.Starter:
      return 'Start with 2 weeks of food'
    case MealPlanType.Trial:
      return undefined
    default:
      return `Delivered every ${deliveryFrequencyWeeks > 1 ? `${deliveryFrequencyWeeks} weeks` : 'week'}`
  }
})

const isOversizedStarter = computed<boolean>(() => {
  return props.mealPlan.feedingPlanType === MealPlanType.Starter && props.mealPlan.deliveryFrequencyWeeks === 1
})

const percentOfCalories = computed<string | undefined>(() => {
  switch (props.mealPlan.feedingPlanType) {
    case MealPlanType.Full:
      return '100%'
    case MealPlanType.Half:
      return '50%'
    case MealPlanType.Starter:
      return isOversizedStarter.value ? '50%' : '100%'
    default:
      return undefined
  }
})

const popular = computed<boolean>(() => {
  return props.mealPlan.feedingPlanType === MealPlanType.Full
})

const showInfoDialog = (): void => {
  emits('info', props.mealPlan)
}
</script>

<template>
  <div
    :id="elementId"
    class="tw-flex-grow tw-flex tw-flex-col tw-relative tw-cursor-pointer"
    @click="emits('select', mealPlan)"
  >
    <div v-if="popular" class="plan-label">
      <div class="plan-popular">
        <StarsIcon />
        <div>Most popular</div>
      </div>
    </div>

    <div class="plan" :aria-selected="selected">
      <div v-if="selected" class="tw-absolute tw-right-4 tw-top-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
          <path
            fill="#10B193"
            d="M7.133 15.686.308 8.713a1.09 1.09 0 0 1 0-1.518l1.484-1.517a1.034 1.034 0 0 1 1.485 0l4.598 4.698L17.723.314a1.034 1.034 0 0 1 1.485 0l1.485 1.517a1.09 1.09 0 0 1 0 1.518L8.616 15.686a1.034 1.034 0 0 1-1.484 0Z"
          />
        </svg>
      </div>

      <div class="plan-details">
        <div class="tw-flex tw-justify-center">
          <img
            :src="image"
            alt="A photo of the Lyka food"
            class="tw-w-20 md:tw-w-auto md:tw-h-32 tw-h-auto"
            height="120"
          />
        </div>
        <div>
          <div class="tw-space-y-1.5">
            <h3 class="tw-font-stylized tw-bold-success tw-text-3xl md:tw-text-4xl lg:tw-text-6xl" v-html="title" />
            <div>{{ description }}</div>
          </div>
          <div class="md:tw-border-y tw-border-mint-green md:tw-py-4 md:tw-mt-4 tw-space-y-1.5 tw-mt-1.5">
            <div v-if="deliveryDescription" class="tw-font-bold">{{ deliveryDescription }}</div>
            <div>
              <button
                type="button"
                class="tw-select-none focus:tw-outline-none tw-underline hover:tw-text-green-dark tw-inline-flex tw-gap-1 tw-items-center tw-text-green tw-font-bold"
                @click.prevent.stop="showInfoDialog"
              >
                <span v-if="mealPlan.feedingPlanType === MealPlanType.Trial" class="tw-font-bold"
                  >{{ TRIAL_PLAN_POUCH_QUANTITY }} × {{ TRIAL_PLAN_POUCH_SIZE_GRAMS }}g taster pouches</span
                >
                <span v-else>{{ percentOfCalories }} of their diet</span>
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  width="20"
                  height="20"
                  fill="none"
                  class="tw-w-[1em] tw-h-[1em]"
                >
                  <path
                    fill="#10B193"
                    d="M10 20a10 10 0 1 0 0-20 10 10 0 0 0 0 20ZM6.633 6.457A2.191 2.191 0 0 1 8.695 5h2.278a2.465 2.465 0 0 1 1.226 4.605l-1.261.723a.94.94 0 0 1-.938.922.935.935 0 0 1-.938-.938v-.527c0-.336.18-.644.473-.812l1.73-.993a.59.59 0 0 0-.292-1.101H8.695a.308.308 0 0 0-.293.207l-.015.047a.937.937 0 0 1-1.196.57.94.94 0 0 1-.57-1.195l.016-.047-.004-.004ZM8.75 13.75a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
                  />
                </svg> -->
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="plan-price">
        <PlanCardPrice :meal-plan="mealPlan" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.plan {
  @apply tw-border tw-border-mint-green tw-rounded-2xl tw-bg-white tw-text-left tw-text-sm tw-relative tw-flex-1 tw-flex tw-flex-col tw-transition-all tw-select-none;
  @apply aria-selected:tw-border-green aria-selected:tw-bg-mint-green-light aria-selected:tw-shadow-[inset_0_0_0_1px_#10b193];
  @apply md:tw-text-center md:tw-text-base;
  @apply hover:tw-border-green;
}

.plan-label {
  @apply tw-flex tw-px-1 tw-bottom-full -tw-mb-2;
  @apply md:tw-self-center md:tw-mx-auto md:tw-z-10 md:tw-left-1/2 md:-tw-translate-x-1/2 md:tw-absolute md:tw-mb-0 md:tw-translate-y-1/2;
}

.plan-popular {
  @apply tw-h-10 tw-bg-green tw-rounded-t-2xl tw-whitespace-nowrap tw-flex tw-gap-2 tw-items-center tw-pb-4 tw-px-4 tw-py-2 tw-text-sm tw-text-white;
  @apply md:tw-rounded-full md:tw-pb-2;
}

.plan-details {
  @apply tw-p-4 sm:tw-p-6 tw-flex md:tw-flex-col tw-gap-6 tw-items-center tw-flex-1 tw-space-y-2;
  @apply md:tw-items-stretch md:tw-gap-0;
}

.plan-price {
  @apply tw-border-t tw-border-mint-green tw-p-4 md:tw-border-t-0;
  @apply sm:tw-p-6;
  @apply md:tw-pt-0;
}
</style>
