import { defineStore } from 'pinia'
import { useDogsStore } from './dogs'
import { useRecommendationStore } from './recommendation'
import { useAuthStore } from './auth'
import { useClient } from '@/services/useClient'
import { type LocationStep, useLocationStep } from '@/steps/location'

interface Dependencies {
  client?: typeof useClient
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createEcommerceStore = ({ client = useClient }: Dependencies = {}) => {
  return defineStore('ecommerce-phoenix', () => {
    const dogsStore = useDogsStore()
    const recommendationStore = useRecommendationStore()
    const authStore = useAuthStore()

    const checkout = async (): Promise<string | undefined> => {
      const userAccessToken = authStore.getAccessToken()

      if (!userAccessToken) {
        throw new Error('User is not authenticated')
      }

      const animals = dogsStore.dogs.map((dog) => {
        const recipes = recommendationStore.getRecipesForDog(dog.identifier)
        const feedingPlanType = recommendationStore.getPlanForDog(dog.identifier)

        if (!feedingPlanType) {
          throw new Error('Feeding plan not set')
        }

        return {
          animalId: dog.identifier,
          feedingPlanType,
          recipes,
        }
      })

      const { postcode, suburb } = useLocationStep() as LocationStep

      if (!postcode.value || !suburb.value) {
        throw new Error('Suburb or postcode not set')
      }

      const body = {
        userAccessToken,
        animals,
        postcode: postcode.value,
        suburb: suburb.value,
      }

      const response = await client().bab.checkout({ body })

      if (response.status === 201) {
        return response.body.checkoutUrl
      }
    }

    return {
      checkout,
    }
  })
}

export const useEcommerceStore = createEcommerceStore()
