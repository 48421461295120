import { computed } from 'vue'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useHealthIssuesStep } from './healthIssues'
import { StepName } from '@/stores/phoenix/steps'
import { useUserStore } from '@/stores/phoenix/user'
import { useDogsStore } from '@/stores/phoenix/dogs'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineGuestUserStep = () => {
  const { busy } = useStep()
  const userStore = useUserStore()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return userStore.currentUser !== undefined
  })

  const getPreviousStep = (): IStep | undefined => {
    return useHealthIssuesStep()
  }

  const getNextStep = (): IStep | undefined => {
    return undefined
  }

  const createGuestUser = async ({ email, firstName }: { email: string; firstName: string }): Promise<void> => {
    // TODO: Handle updating user if already exists
    if (!userStore.currentUser) {
      await userStore.createGuestUser({ email, firstName })

      // Associate the current user with the dogs
      await dogsStore.associateDogs()
    }
  }

  const firstName = computed(() => {
    return userStore.currentUser?.firstName
  })

  const email = computed(() => {
    return userStore.currentUser?.email
  })

  return {
    name: StepName.User,
    busy,
    valid,
    dogs: dogsStore.dogs,
    firstName,
    email,
    createGuestUser,
    getPreviousStep,
    getNextStep,
  }
}

export type GuestUserStep = ReturnType<typeof defineGuestUserStep>

export const useGuestUserStep = registerStep(defineGuestUserStep)
