<script setup lang="ts">
import { ref } from 'vue'
import { LykaButton, LykaFieldList, LykaInput } from '@lyka/ui'
import StepContainer from '../StepContainer.vue'
import StepNavigation from '../StepNavigation.vue'
import SignBorder from '../../components/icons/SignBorder.vue'
import MarketingConsentCheckbox from '../MarketingConsentCheckbox.vue'
import TickCircleIcon from '@/components/icons/TickCircleIcon.vue'
import type { UnservicedLocationStep } from '@/steps/unservicedLocation'

defineProps<{
  step: UnservicedLocationStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const submitted = ref(false)
const submitting = ref(false)

const email = ref('')
const firstName = ref('')
const lastName = ref('')
const marketingCommunication = ref(false)

const submit = (): void => {}
</script>

<template>
  <StepContainer v-if="submitted">
    <div class="tw-flex tw-flex-col tw-items-center tw-space-y-8 tw-mb-28 tw-pt-16 sm:tw-pt-32">
      <TickCircleIcon />

      <h3 class="tw-h1">Thank you!</h3>
      <p>We'll send you an email when we can deliver to your area</p>
    </div>

    <LykaButton block size="lg" href="/"> Return to the Lyka website </LykaButton>
  </StepContainer>

  <StepContainer v-else id="step-unserviced-location">
    <div class="tw-space-y-4 sm:tw-space-y-8 tw-py-8">
      <div class="tw-flex tw-items-center tw-justify-center">
        <SignBorder alt="sign displaying the words coming soon" />
      </div>
      <h2 class="tw-h2">Sorry, Lyka isn't available in your area yet</h2>
      <p>
        We're working as fast as we can to share Lyka with puppers all around Australia! Enter your name and email,
        we'll let you know once we're in your neighbourhood.
      </p>
    </div>

    <form id="formUnserviced" @submit.prevent="submit">
      <div class="tw-space-y-8">
        <LykaFieldList>
          <LykaInput
            v-model.trim="firstName"
            name="first-name"
            label="First name"
            required
            error-message="Your first name is required"
            autocapitalize
          />
          <LykaInput
            v-model.trim="lastName"
            name="last-name"
            label="Last name"
            required
            error-message="Your last name is required"
            autocapitalize
          />
          <LykaInput
            v-model.trim="email"
            name="email"
            label="Email"
            type="email"
            required
            error-message="Your email is required"
          />
        </LykaFieldList>

        <MarketingConsentCheckbox v-model="marketingCommunication" />
      </div>

      <StepNavigation
        submit
        :next-loading="submitting"
        next-text="Notify me"
        :next-icon="false"
        form="formUnserviced"
        @previous="emits('previous')"
      />
    </form>
  </StepContainer>
</template>
