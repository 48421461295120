import type { MealRecipe } from '@lyka/bab-api-contracts/src/bab'

export enum RecipeName {
  Beef = 'beef',
  Chicken = 'chicken',
  Turkey = 'turkey',
  Lamb = 'lamb',
  Kangaroo = 'kangaroo',
  Fish = 'fish',
  Goat = 'goat',
}

export const THERAPEUTIC_RECIPES: ReadonlySet<RecipeName> = new Set([RecipeName.Goat])

export const isTherapeuticRecipe = ({ key }: Pick<MealRecipe, 'key'>): boolean => {
  return THERAPEUTIC_RECIPES.has(key as RecipeName)
}

export const isSuitableRecipe = ({ status }: MealRecipe): boolean => {
  return status === 'recommended' || status === 'neutral'
}

export const isUnsuitableRecipe = ({ status }: MealRecipe): boolean => {
  return status === 'not_recommended'
}

export const getRecipeCallout = (recipeKey: string): string => {
  switch (recipeKey) {
    case RecipeName.Beef: {
      return 'Grass Fed'
    }
    case RecipeName.Lamb: {
      return 'Grass Fed'
    }
    case RecipeName.Chicken: {
      return 'Free Range'
    }
    case RecipeName.Turkey: {
      return 'Barn Raised'
    }
    case RecipeName.Kangaroo: {
      return 'Wild Caught'
    }
    case RecipeName.Fish: {
      return 'Wild Caught'
    }
    case RecipeName.Goat: {
      return 'Rangeland'
    }
  }

  return ''
}
