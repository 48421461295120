import z from 'zod'
import { computed, reactive } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useFussinessStep } from './fussiness'
import { useAllergiesStep } from './allergies'
import { StepName } from '@/stores/phoenix/steps'
import { useDogsStore } from '@/stores/phoenix/dogs'

const StepSchema = z
  .array(
    z.object({
      previousFoods: DogProperties.shape.previousFoods,
    }),
  )
  .min(1)

type PreviouslyFed = z.infer<typeof DogProperties.shape.foodAllergies>

export interface DogPreviouslyFedData {
  identifier: string
  previousFoods?: string[]
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const definePreviouslyFedStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const data = reactive<DogPreviouslyFedData[]>(
    dogsStore.dogs.map(({ identifier, previousFoods }) => ({
      identifier,
      previousFoods,
    })),
  )

  const valid = computed(() => {
    return StepSchema.safeParse(data).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useFussinessStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useAllergiesStep()
  }

  const updatePreviousFoods = async (dog: DogEntity, previousFoods: PreviouslyFed): Promise<void> => {
    const dogData = data.find((d) => d.identifier === dog.identifier)

    if (!dogData) {
      return
    }

    Object.assign(dogData, { previousFoods })

    if (!valid.value) {
      return
    }

    await dogsStore.updateDog(dog, { previousFoods })
  }

  return {
    name: StepName.PreviouslyFed,
    busy,
    valid,
    dogs: dogsStore.dogs,
    foodTypes: dogsStore.foodTypes,
    updatePreviousFoods,
    getPreviousStep,
    getNextStep,
  }
}

export type PreviouslyFedStep = ReturnType<typeof definePreviouslyFedStep>

export const usePreviouslyFedStep = registerStep(definePreviouslyFedStep)
