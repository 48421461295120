<script setup lang="ts">
import { computed } from 'vue'
import { LykaContainer } from '@lyka/ui'
import type { DogEntity, FeedingPlan, MealRecipe } from '@lyka/bab-api-contracts/src/bab'
import TrustpilotReviews from '../TrustpilotReviews.vue'
import MiniHr from '../common/MiniHr.vue'
import AnyQuestion from '../common/AnyQuestion.vue'
import StepNavigation from '../StepNavigation.vue'
import TickIcon from '../icons/TickIcon.vue'
import PlanFirstBox from './plan/PlanFirstBox.vue'
import PlanOptions from './plan/PlanOptions.vue'
import type { PlanStep } from '@/steps/plan'
import { isTherapeuticRecipe } from '@/models/Recipe'

const props = defineProps<{
  step: PlanStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const hasTherapeuticRecipes = (dog: DogEntity): boolean => {
  const selectedIds = props.step.selectedRecipes[dog.identifier]
  const dogRecipes = props.step.recipes[dog.identifier]

  return (
    dogRecipes
      ?.filter((recipe) => selectedIds?.includes(recipe.identifier))
      .some((recipe) => isTherapeuticRecipe(recipe)) ?? false
  )
}

// const hasSocialGiftingCoupon = computed(() => coupons.socialGiftingCoupon.value)
const hasSocialGiftingCoupon = computed(() => false)

// const starterPlanSelected = computed(() => {
//   const firstDogId = props.step.dogs[0]?.identifier ?? ''
//   const selectedPlan = props.step.selectedPlan[firstDogId]

//   return selectedPlan === MealPlanType.Starter
// })

const recipes = computed(() => {
  return props.step.dogs.reduce((result, dog) => {
    return {
      ...result,
      [dog.identifier]:
        props.step.recipes[dog.identifier]?.filter((recipe) =>
          props.step.selectedRecipes[dog.identifier]?.includes(recipe.identifier),
        ) ?? [],
    }
  }, {} as Record<string, MealRecipe[]>)
})

const disabled = computed<boolean>(() => !props.step.valid.value || props.step.submitting.value)

const onPlanSelected = async (dog: DogEntity, plan: FeedingPlan): Promise<void> => {
  props.step.updateDogPlan(dog, plan)
}

const submit = (): void => {
  if (disabled.value) {
    return
  }

  props.step.checkout()
}
</script>

<template>
  <div id="step-plan" ref="stepNav">
    <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <!-- Social Gifting Coupon -->
          <!-- <PlanSocialGiftingStarter
            v-if="hasSocialGiftingCoupon"
            :meal-plan="starterPlan!"
            :meal-weights="mealPlanStore.mealWeights"
          /> -->

          <PlanOptions
            v-for="dog in props.step.dogs"
            :key="dog.identifier"
            :dog="dog"
            :selected-meal-plan-type="step.selectedPlan[dog.identifier]"
            :meal-plans="step.plans[dog.identifier]"
            :therapeutic="hasTherapeuticRecipes(dog)"
            @select="(mealPlan) => onPlanSelected(dog, mealPlan)"
          />

          <div class="tw-space-y-8">
            <h3 class="tw-h2">Feeding with Lyka is flexible</h3>
            <ul class="tw-space-y-1 tw-text-base sm:tw-text-lg md:tw-text-xl tw-w-max tw-mx-auto">
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Cancel anytime, hassle-free</li>
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Control your delivery time</li>
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Simple swaps and changes</li>
            </ul>
          </div>
        </div>
      </LykaContainer>

      <!-- <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox v-if="hasSocialGiftingCoupon" :recipes="recipes[step.dogs[0]?.identifier ?? '']">
            <template #heading>
              Included in your
              <span class="tw-text-green tw-whitespace-nowrap">Starter Box</span>
            </template>
          </PlanFirstBox>

          <PlanSocialGiftingWhatHappensNext v-if="hasSocialGiftingCoupon" />
        </div>
      </LykaContainer> -->

      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox
            v-if="!hasSocialGiftingCoupon"
            :meal-plan-type="step.selectedPlan[step.dogs[0]?.identifier ?? '']"
            :recipes="recipes[step.dogs[0]?.identifier ?? '']"
          />

          <div>
            <LykaContainer size="md" class="tw-space-y-4 tw-mb-8">
              <h2 class="tw-h2 tw-mb-6"><b class="tw-text-green tw-block">Real changes.</b> Real dogs.</h2>

              <p class="tw-lead">
                Taking pride of place in dog bowls around the country, discover how Lyka is changing the game and
                improving lives.
              </p>
            </LykaContainer>

            <TrustpilotReviews />
          </div>

          <div class="tw-space-y-2 sm:tw-space-y-6">
            <MiniHr />

            <AnyQuestion center />
          </div>
        </div>
      </LykaContainer>
    </div>
  </div>

  <StepNavigation
    :next-loading="step.submitting.value"
    :next-disabled="disabled"
    next-text="Checkout"
    @previous="emit('previous')"
    @next="submit"
  />
</template>
