import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { unwrapData, useApi } from '../composables/useApi'
import type { Treat } from '@/models/Treat'
import type { Product } from '@/models/Product'

type TreatsResponse = Treat[]

export const useTreatsStore = defineStore('treats', () => {
  const treats = ref<Treat[]>([])
  const loaded = ref(false)
  const api = useApi()

  const load = async (): Promise<void> => {
    if (loaded.value) {
      return
    }

    try {
      const { data } = await api.get<TreatsResponse>(
        'extras/availablecustomer',
        {
          excludeCollections: 1,
          buildABox: 1,
        },
        unwrapData,
      )

      treats.value = data
    } finally {
      loaded.value = true
    }
  }

  const products = computed<Product[]>(() => {
    return treats.value.flatMap(({ products }) => products)
  })

  const findTreat = (treatId: number): Treat | undefined => {
    return treats.value.find(({ id }) => id === treatId)
  }

  const findProductByName = (name: string): Product | undefined => {
    return products.value.find((product) => product.name === name)
  }

  const findProduct = (productId: number): Product | undefined => {
    return products.value.find((product) => product.id === productId)
  }

  // Sort treats by limited stock
  const orderedTreats = computed(() => {
    return treats.value.sort((a, b) => {
      if (a.limitedStock) {
        return 1
      }

      if (b.limitedStock) {
        return -1
      }

      return 0
    })
  })

  const activeTreats = computed<Treat[]>(() => {
    return treats.value.filter((treat) => treat.active && treat.activeCustomer)
  })

  return {
    load,
    treats: orderedTreats,
    activeTreats,
    products,
    findTreat,
    findProductByName,
    findProduct,
  }
})
