import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useClient } from '@/services/useClient'

interface Tokens {
  userAccessToken: string
  userRefreshToken: string
}

const LOCALSTORAGE_TOKEN_KEY = 'Lyka::Tokens'

type StoreStorage = Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>

type Dependencies = Partial<{
  storage: StoreStorage
  client: typeof useClient
}>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createAuthStore = ({ storage = localStorage, client = useClient }: Dependencies = {}) => {
  return defineStore('auth-phoenix', () => {
    const loadTokens = (): Tokens | undefined => {
      try {
        const json = storage.getItem(LOCALSTORAGE_TOKEN_KEY)

        if (json) {
          return JSON.parse(json)
        }
      } catch {
        return undefined
      }
    }

    const saveTokens = (tokens: Tokens): void => {
      try {
        storage.setItem(LOCALSTORAGE_TOKEN_KEY, JSON.stringify(tokens))
      } catch {}
    }

    const tokens = ref<Tokens | undefined>(loadTokens())

    const setTokens = (newTokens: Tokens): void => {
      tokens.value = newTokens

      saveTokens(newTokens)
    }

    const logout = (): void => {
      tokens.value = undefined
    }

    const getAccessToken = (): string | undefined => {
      return tokens.value?.userAccessToken
    }

    const getRefreshToken = (): string | undefined => {
      return tokens.value?.userRefreshToken
    }

    const reauthenticate = async (): Promise<void> => {
      const userRefreshToken = getRefreshToken()
      const userAccessToken = getAccessToken()

      if (!userRefreshToken || !userAccessToken) {
        return
      }

      const body = { userRefreshToken, userAccessToken }

      const result = await useClient().bab.reauthenticate({ body })

      if (result.status === 201) {
        setTokens(result.body)
      }

      if (result.status === 401) {
        logout()
      }
    }

    return {
      getAccessToken,
      reauthenticate,
      setTokens,
      logout,
    }
  })
}

export const useAuthStore = createAuthStore()
