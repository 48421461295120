import type { DogEntity } from '@lyka/bab-api-contracts/src/bab'
// import { useSegment } from '@lyka/vue-common/composables/useSegment'

interface EventData {
  weight: number
  minAverageWeight: number
  maxAverageWeight: number
  dog: DogEntity
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWeightWarningEvent = ({ weight, dog, minAverageWeight, maxAverageWeight }: EventData) => {
  const send = (): void => {
    // useSegment().track('Dog Weight Warning Shown', {
    //   weight,
    //   minAverageWeight,
    //   maxAverageWeight,
    //   breedPrimary: dog.breed.primary?.name ?? null,
    //   breedSecondary: dog.breed.secondary?.name ?? null,
    //   breedType: dog.breed.type,
    // })
  }

  return {
    send,
  }
}
