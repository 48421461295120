import z from 'zod'
import { computed } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { useAgeStep } from './age'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useBodyShapeStep } from './bodyShape'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'

const StepSchema = z
  .array(
    z.object({
      weight: DogProperties.shape.weight,
    }),
  )
  .min(1)

export type WeightStepData = z.infer<typeof StepSchema>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineWeightStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useAgeStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useBodyShapeStep()
  }

  const updateWeight = async (dog: DogEntity, weight: number): Promise<void> => {
    await dogsStore.updateDog(dog, { weight })
  }

  return {
    name: StepName.Weight,
    busy,
    dogs: dogsStore.dogs,
    breeds: dogsStore.breeds,
    valid,
    getPreviousStep,
    getNextStep,
    updateWeight,
  }
}

export type WeightStep = ReturnType<typeof defineWeightStep>

export const useWeightStep = registerStep(defineWeightStep)
