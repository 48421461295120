import { Gender } from './Dog'

const DEFAULT_WEIGHT_KG = 7

export interface Breed {
  id: number
  name: string
  adultAgeMonthsMax: number
  adultWeightFemaleMax: number
  adultWeightFemaleMin: number
  adultWeightMaleMax: number
  adultWeightMaleMin: number
  active?: boolean
  maleSizeClassification: 'teacup' | 'toy' | 'small' | 'medium' | 'large' | 'giant'
  breedExamples?: string
}

export const getAverageWeight = (breed: Breed): number => {
  return (
    (breed.adultWeightFemaleMax + breed.adultWeightFemaleMin + breed.adultWeightMaleMax + breed.adultWeightMaleMax) / 4
  )
}

type BreedWeightData = Pick<
  Breed,
  'adultWeightFemaleMin' | 'adultWeightFemaleMax' | 'adultWeightMaleMin' | 'adultWeightMaleMax'
>

export const getWeightRange = (gender: Gender, ...breeds: BreedWeightData[]): [number, number] => {
  if (!breeds.length || !gender) {
    return [DEFAULT_WEIGHT_KG, DEFAULT_WEIGHT_KG]
  }

  switch (gender) {
    case Gender.Boy: {
      const min = breeds.reduce((sum, { adultWeightMaleMin: min }) => sum + min, 0) / breeds.length
      const max = breeds.reduce((sum, { adultWeightMaleMax: max }) => sum + max, 0) / breeds.length

      return [min, max]
    }

    case Gender.Girl: {
      const min = breeds.reduce((sum, { adultWeightFemaleMin: min }) => sum + min, 0) / breeds.length
      const max = breeds.reduce((sum, { adultWeightFemaleMax: max }) => sum + max, 0) / breeds.length

      return [min, max]
    }
  }
}

export const getAdultWeight = (gender: Gender, ...breeds: BreedWeightData[]): number => {
  const [min, max] = getWeightRange(gender, ...breeds)

  return (min + max) / 2
}

const WEIGHT_FACTOR = 0.5

export const withinExpectedWeightRange = (
  weight: number,
  minAverageWeight: number,
  maxAverageWeight: number,
): boolean => {
  const minThreshold = minAverageWeight * WEIGHT_FACTOR
  const maxThreshold = maxAverageWeight * (1 + WEIGHT_FACTOR)

  return weight <= maxThreshold && weight >= minThreshold
}
