<script setup lang="ts">
import { LykaButton, LykaModal } from '@lyka/ui'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type StepName, useStepsStore } from '@/stores/steps'

defineProps<{
  open: boolean
}>()

const emits = defineEmits<{
  (e: 'continue', step: StepName): void
  (e: 'close'): void
}>()

const stepsStore = useStepsStore()

const handleAction = async (action: string): Promise<void> => {
  const lastStepName = stepsStore.getLastStepName()

  useSegment().track('Continue Session Completed', {
    action,
    stepName: lastStepName,
  })

  switch (action) {
    case 'Dismiss': {
      emits('close')
      break
    }
    case 'Continue': {
      emits('continue', lastStepName)
      break
    }
  }
}
</script>

<template>
  <LykaModal :open="open" title="Continue session?" @close="emits('close')">
    <div class="tw-space-y-2">
      <h4 class="tw-h3">Welcome back!</h4>
      <p>Would you like to continue your previous session?</p>
    </div>

    <template #footer>
      <LykaButton
        id="previous-session-cancel"
        hide-at="sm"
        text-link
        decoration
        variant="alt"
        @click="handleAction('Dismiss')"
      >
        Dismiss
      </LykaButton>
      <LykaButton id="previous-session-continue" block-at="sm" @click="handleAction('Continue')">
        Yes, continue
      </LykaButton>
    </template>
  </LykaModal>
</template>
