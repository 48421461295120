import { defineStore } from 'pinia'
import { ref } from 'vue'
import { z } from 'zod'

const STATE_STORAGE_KEY = 'Lyka::BuildABox::Phoenix'

// Namespace the stored state so that if we do introduce breaking changes we can force users to the new version (and
// wipe their saved state). In general we want to avoid this and instead migrate user data (see below).
const STATE_STORAGE_VERSION = 'V1'

const State = z.object({
  dogs: z.array(z.string()),
  location: z.object({
    postcode: z.string().optional(),
    suburb: z.string().optional(),
  }),
})

export const defaultState = State.parse({
  dogs: [],
  location: {},
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
type State = z.infer<typeof State>

export const storageKey = (): string => {
  return [STATE_STORAGE_KEY, STATE_STORAGE_VERSION].join('::')
}

type StoreStorage = Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createStateStore = (storage: StoreStorage = localStorage) => {
  return defineStore('state-phoenix', () => {
    const state = ref<State>(defaultState)

    const save = (): void => {
      storage.setItem(storageKey(), JSON.stringify(state.value))
    }

    const $reset = (): void => {
      state.value = defaultState
    }

    const load = (): State => {
      const json = storage.getItem(storageKey())

      if (json) {
        const data = JSON.parse(json)
        const result = State.safeParse(data)

        if (result.success) {
          state.value = result.data
        }
      }

      return state.value
    }

    const getProperty = <K extends keyof State>(property: K): State[K] => {
      return state.value[property]
    }

    const setProperty = <K extends keyof State>(property: K, value: State[K]): void => {
      state.value[property] = value

      save()
    }

    load()

    return {
      state,

      load,
      save,

      getProperty,
      setProperty,

      $reset,
    }
  })
}

export const useStateStore = createStateStore()
