import { useClient } from '@/services/useClient'
import { useAuthStore } from '@/stores/auth'
import { useDogsStore } from '@/stores/dogs'
import { type PromiseResult, failureResult, successResult } from '@/utils/Result'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBeenHereBeforeRequest = () => {
  const authStore = useAuthStore()
  const dogsStore = useDogsStore()

  const send = async (email: string): PromiseResult<true, Error> => {
    const result = await useClient().bab.authenticateGuestUser({ body: { email } })

    if (result.status === 200) {
      authStore.setTokens(result.body)
      dogsStore.load()

      return successResult(true)
    }

    return failureResult(new Error('Not implemented'))
  }

  return {
    send,
  }
}
