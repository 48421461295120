import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { LykaUser } from '@lyka/bab-api-contracts/src/bab'
import { useClient } from '@/services/useClient'
import { useAuth } from '@/composables/phoenix/auth'

interface Dependencies {
  client?: typeof useClient
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createUserStore = ({ client = useClient }: Dependencies = {}) => {
  return defineStore('user-phoenix', () => {
    const currentUser = ref<LykaUser>()

    const $reset = (): void => {
      currentUser.value = undefined
    }

    const createGuestUser = async ({ email, firstName }: { email: string; firstName: string }): Promise<void> => {
      const response = await client().bab.createGuestUser({
        body: {
          email,
          firstName,
        },
      })

      if (response.status === 201) {
        const tokens = response.body

        if (tokens) {
          useAuth().setTokens(tokens)
        }
      }
    }

    const loadCurrentUser = async (): Promise<LykaUser | undefined> => {
      const userAccessToken = useAuth().getAccessToken()

      if (!userAccessToken) {
        return
      }

      const response = await client().bab.getCurrentUser({
        query: {
          userAccessToken,
        },
      })

      if (response.status === 200) {
        currentUser.value = response.body
      }

      return currentUser.value
    }

    const load = async (): Promise<void> => {
      await loadCurrentUser()
    }

    return {
      createGuestUser,
      loadCurrentUser,

      load,

      $reset,

      currentUser,
    }
  })
}

export const useUserStore = createUserStore()
