<script lang="ts" setup>
import { computed, ref } from 'vue'
import { LykaChip, LykaRadio } from '@lyka/ui'
import type { DogEntity, DogFoodType } from '@lyka/bab-api-contracts/src/bab'
import StepHeading from '@/components/StepHeading.vue'

const props = defineProps<{
  dogIndex: number
  foodTypes: DogFoodType[]
  dog: DogEntity
}>()

const emit = defineEmits<{
  (e: 'update', foods: string[]): void
}>()

const selectedFoods = ref<string[]>(props.dog.previousFoods ?? [])

const updateFoods = (value: string[]): void => {
  selectedFoods.value = value
  emit('update', value)
}

const dogFoodTypeUnknown = computed({
  get() {
    return !props.dog.previousFoods?.length
  },
  set() {
    selectedFoods.value = []
    emit('update', [])
  },
})
</script>

<template>
  <div class="tw-space-y-12">
    <div>
      <StepHeading> What has {{ dog.name }} been eating? </StepHeading>
      <div class="tw-space-y-12">
        <div class="tw-flex tw-justify-center tw-flex-wrap tw-gap-1 tw-items-center">
          <LykaChip
            v-for="foodType in foodTypes"
            :key="foodType.identifier"
            :model-value="selectedFoods"
            type="checkbox"
            size="lg"
            block
            :input-value="foodType.identifier"
            :name="`food-type-${dog.identifier}`"
            @update:model-value="updateFoods"
            >{{ foodType.name }}</LykaChip
          >
        </div>

        <LykaRadio
          v-model="dogFoodTypeUnknown"
          radio
          type="button"
          size="lg"
          block
          :input-value="true"
          :name="`food-type-${dog.identifier}`"
          label="I'm not sure"
        />
      </div>
    </div>
  </div>
</template>
