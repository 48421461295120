import z from 'zod'
import { computed } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useLocationStep } from './location'
import { useBreedStep } from './breed'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'

const StepSchema = z
  .array(
    z.object({
      gender: DogProperties.shape.gender,
    }),
  )
  .min(1)

export type GenderStepData = z.infer<typeof StepSchema>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineGenderStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useLocationStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useBreedStep()
  }

  const updateGender = async (dog: DogEntity, gender: DogEntity['gender']): Promise<void> => {
    await dogsStore.updateDog(dog, { gender })
  }

  const load = async (): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
  }

  return {
    name: StepName.Gender,
    busy,
    valid,
    dogs: dogsStore.dogs,
    updateGender,
    getPreviousStep,
    getNextStep,
    load,
  }
}

export type GenderStep = ReturnType<typeof defineGenderStep>

export const useGenderStep = registerStep(defineGenderStep)
