import { initClient } from '@ts-rest/core'
import { contract } from '@lyka/bab-api-contracts/src/index'

const client = initClient(contract, {
  baseUrl: import.meta.env.VITE_BUILD_A_BOX_BFF_URL,
  baseHeaders: {
    'x-app-source': 'ts-rest',
  },
})

export const useClient = (): typeof client => {
  return client
}
