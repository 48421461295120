<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl } from '@lyka/utils'
import { BodyType } from '@lyka/bab-api-contracts/src/bab'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import OptionGroup from '@/components/common/OptionGroup.vue'
import OptionTile from '@/components/common/OptionTile.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { BodyShapeStep } from '@/steps/bodyShape'

const props = defineProps<{
  step: BodyShapeStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const bodyShapes = [
  { name: 'slim', title: 'A bit slim', description: 'Skinny waist with visible ribs', value: BodyType.Underweight },
  { name: 'ideal', title: 'Just right', description: 'Visible waist and easy to feel ribs', value: BodyType.Average },
  {
    name: 'chubby',
    title: 'Carries a bit extra',
    description: 'Hidden waist, hard to find ribs',
    value: BodyType.Overweight,
  },
] as const

const bodyShapeImageURL = (name: string): string => {
  return assetUrl(`images/steps/body-shape/${name}.svg`)
}

const disabled = computed(() => !props.step.valid.value)
</script>

<template>
  <StepsContainer id="step-body-shape" size="lg">
    <form :id="step.name" v-submit @submit.prevent="emit('next')">
      <div v-for="(dog, index) in props.step.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading> What is {{ dog.name }}’s body shape? </StepHeading>

        <OptionGroup>
          <OptionTile
            v-for="bodyShape in bodyShapes"
            :key="bodyShape.value"
            :title="bodyShape.title"
            :description="bodyShape.description"
            :input-value="bodyShape.value"
            :model-value="dog.bodyType"
            input-type="radio"
            @update:model-value="(value) => props.step.updateBodyShape(dog, value as BodyType)"
          >
            <template #icon>
              <img :src="bodyShapeImageURL(bodyShape.name)" />
            </template>
          </OptionTile>
        </OptionGroup>
      </div>

      <StepNavigation submit :next-disabled="disabled" :form="step.name" @previous="emit('previous')" />
    </form>
  </StepsContainer>
</template>
