<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import { type Component } from 'vue'
import FoodBowlIcon from '@/components/icons/FoodBowlIcon.vue'
import SeedlingIcon from '@/components/icons/SeedlingIcon.vue'
import MiniTruckIcon from '@/components/icons/MiniTruckIcon.vue'

const emits = defineEmits<{
  (e: 'dismiss'): void
  (e: 'continue'): void
}>()

interface Feature {
  icon: Component
  heading: string
  description: string
}

const features: Feature[] = [
  {
    icon: SeedlingIcon,
    heading: 'Real food',
    description: 'Human-grade, gently cooked.',
  },
  {
    icon: FoodBowlIcon,
    heading: 'Ready-to-serve',
    description: 'Custom-portioned meals for your dog.',
  },
  {
    icon: MiniTruckIcon,
    heading: 'Convenient and fresh',
    description: 'Skip the stores and artificial preservatives.',
  },
]
</script>

<template>
  <main class="tw-min-h-[100svh] tw-bg-offwhite md:tw-flex md:tw-flex-col">
    <header class="tw-pt-8 tw-pl-8 md:tw-pt-32 md:tw-pl-32">
      <h1 class="tw-flex md:tw-justify-start">
        <a href="/"
          ><img alt="Lyka" src="@/assets/images/logo-green.svg" width="81" height="47" class="md:tw-w-[100px]"
        /></a>
      </h1>
    </header>

    <div
      class="tw-px-7 tw-pt-10 tw-pb-10 md:tw-px-32 tw-flex tw-flex-col tw-justify-center tw-mb-10 md:tw-mt-auto md:tw-mb-auto"
    >
      <div class="md:tw-gap-14 tw-max-w-7xl">
        <div class="tw-flex tw-flex-col md:tw-min-h-0">
          <section
            class="md:tw-text-left md:tw-max-w-full tw-max-w-md tw-mx-auto lg:tw-mx-0 tw-pt-8 tw-flex tw-flex-col md:tw-gap-6 tw-gap-4"
          >
            <h2 class="tw-h1">The best nutrition is personal.</h2>
            <p class="tw-leading-6 md:tw-text-xl tw-text-sm md:tw-max-w-md md:tw-leading-8">
              Let’s find the right mix of meals, portion size and plan for your dogs needs.
            </p>
            <p class="tw-leading-6 tw-text-sm tw-opacity-80">This will take around 3 minutes.</p>

            <div>
              <LykaButton
                id="getting-started-build"
                block-at="sm"
                size="lg"
                variant="primary"
                @click="emits('dismiss')"
              >
                Build your meal plan
              </LykaButton>
            </div>
          </section>

          <div class="md:tw-text-left tw-text-center md:tw-pt-12 tw-pt-6">
            Been here before?
            <button id="getting-started-continue" class="tw-link" @click="emits('continue')">Continue here</button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <hr class="tw-text-mint-green" />

      <div class="tw-py-12 tw-px-8 tw-flex tw-justify-center">
        <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-6 tw-justify-around tw-w-full">
          <div
            v-for="feature in features"
            :key="feature.heading"
            class="tw-flex md:tw-flex-col tw-flex-row tw-gap-4 md:tw-items-center"
          >
            <component :is="feature.icon" class="md:tw-w-auto tw-w-[16px] md:tw-mt-0 -tw-mt-1" />
            <div class="tw-flex tw-flex-col tw-gap-1.5 md:tw-gap-2.5 md:tw-items-center">
              <h4>
                {{ feature.heading }}
              </h4>
              <p class="tw-opacity-80 tw-text-sm md:tw-text-center">
                {{ feature.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
