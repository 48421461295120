import { computed } from 'vue'
import { useLocationStep } from './location'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineUnservicedLocationStep = () => {
  const { busy } = useStep()

  const submit = async (): Promise<void> => {}

  const valid = computed(() => {
    return true
  })

  const getPreviousStep = (): IStep => {
    return useLocationStep()
  }

  const getNextStep = (): undefined => {
    return undefined
  }

  return {
    name: StepName.UnservicedLocation,
    busy,
    valid,
    submit,
    getPreviousStep,
    getNextStep,
  }
}

export type UnservicedLocationStep = ReturnType<typeof defineUnservicedLocationStep>

export const useUnservicedLocationStep = registerStep(defineUnservicedLocationStep)
