import { initContract } from '@ts-rest/core'
import z from 'zod'

const c = initContract()

export const healthContract = c.router({
  health: {
    method: 'GET',
    path: '/health',
    summary: 'Check the health of the app',
    responses: {
      200: z.object({
        status: z.literal('OK'),
      }),
    },
  },

  services: {
    method: 'GET',
    path: '/health/services',
    summary: 'Check the health of the services',
    responses: {
      200: z.array(
        z.object({
          service: z.string(),
          status: z.enum(['OK', 'ERROR']),
        }),
      ),
    },
  },
})
