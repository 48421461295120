<script setup lang="ts">
import { computed } from 'vue'
import { LykaChip, LykaMessage } from '@lyka/ui'
import type { DogEntity, DogFoodAllergen } from '@lyka/bab-api-contracts/src/bab'
import StepHeading from '@/components/StepHeading.vue'

const props = defineProps<{
  dog: DogEntity
  dogIndex: number
  allergens: DogFoodAllergen[]
}>()

const emits = defineEmits<{
  (e: 'update', allergies: string[]): void
}>()

const allergies = computed({
  get() {
    return props.dog.foodAllergies ?? []
  },

  set(allergies) {
    emits('update', allergies ?? [])
  },
})

const inputName = computed(() => {
  return `allergies-${props.dog.identifier}`
})

const isFirstDog = computed(() => {
  return props.dogIndex === 0
})

const noAllergies = computed({
  get() {
    return props.dog.foodAllergies?.length === 0
  },
  set() {
    allergies.value = []
  },
})
</script>

<template>
  <div>
    <StepHeading> Does {{ dog.name }} have any intolerances or allergies? </StepHeading>

    <LykaMessage v-if="isFirstDog" arrow="top" class="tw-mb-6 -tw-mt-3">
      Lyka meals are already free from gluten, soy, dairy, lactose and sugar.
    </LykaMessage>

    <div class="tw-flex tw-justify-center tw-flex-wrap tw-gap-1 tw-items-center">
      <LykaChip v-model="noAllergies" type="radio" :name="inputName" :input-value="true">None</LykaChip>
      <LykaChip
        v-for="allergen in allergens"
        :key="allergen.slug"
        v-model="allergies"
        :input-value="allergen.slug"
        type="checkbox"
        :name="inputName"
      >
        {{ allergen.name }}
      </LykaChip>
    </div>
  </div>
</template>
