<script setup lang="ts">
import { LykaFieldList, LykaRadio } from '@lyka/ui'
import type { DogEntity } from '@lyka/bab-api-contracts/src/bab'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { GenderStep } from '@/steps/phoenix/gender'

type Gender = NonNullable<DogEntity['gender']>

const props = defineProps<{
  step: GenderStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const genderOptions = [
  { label: 'Boy', value: 'male' },
  { label: 'Girl', value: 'female' },
] as const

const updateGender = (dog: DogEntity, gender: Gender): void => {
  props.step.updateGender(dog, gender)
}

const submit = (): void => {
  emits('next')
}
</script>

<template>
  <StepsContainer>
    <form id="form-gender" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in step.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading>Is {{ dog.name }} a boy or a girl? </StepHeading>

        <LykaFieldList>
          <LykaRadio
            v-for="option in genderOptions"
            :key="option.value"
            :model-value="dog.gender"
            block
            :input-value="option.value"
            type="button"
            size="lg"
            :label="option.label"
            :name="`gender-${index}`"
            @update:model-value="(value) => updateGender(dog, value as Gender)"
          />
        </LykaFieldList>
      </div>

      <StepNavigation submit :next-disabled="!step.valid" form="form-gender" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
