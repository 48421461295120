<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl } from '@lyka/utils'
import { FussinessLevel } from '@lyka/bab-api-contracts/src/bab'
import OptionGroup from '../common/OptionGroup.vue'
import OptionTile from '../common/OptionTile.vue'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { FussinessStep } from '@/steps/phoenix/fussiness'

const props = defineProps<{
  step: FussinessStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const fussinessLevels = [
  { name: 'foodie', title: 'Will eat anything', description: 'The see-food diet', value: FussinessLevel.Low },
  { name: 'choosy', title: 'Can be choosy', description: 'Variety is the spice of life', value: FussinessLevel.Medium },
  {
    name: 'fussy',
    title: 'Seriously fussy',
    description: 'Only the finest wines and dines',
    value: FussinessLevel.High,
  },
] as const

const fussinessImageURL = (name: string): string => {
  return assetUrl(`images/steps/fussiness/${name}.svg`)
}

const disabled = computed(() => !props.step.valid.value)
</script>

<template>
  <StepsContainer size="lg">
    <form id="formFussy" v-submit @submit.prevent="emit('next')">
      <div v-for="(dog, index) in step.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading> Is {{ dog.name }} a fussy eater? </StepHeading>

        <OptionGroup>
          <OptionTile
            v-for="fussinessLevel in fussinessLevels"
            :key="fussinessLevel.name"
            :model-value="dog.eatingHabits"
            input-type="radio"
            :input-value="fussinessLevel.value"
            :title="fussinessLevel.title"
            :description="fussinessLevel.description"
            @update:model-value="(value) => props.step.updateFussiness(dog, value as FussinessLevel)"
          >
            <template #icon>
              <img :src="fussinessImageURL(fussinessLevel.name)" />
            </template>
          </OptionTile>
        </OptionGroup>
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formFussy" @previous="emit('previous')" />
    </form>
  </StepsContainer>
</template>
