<script setup lang="ts">
import { computed } from 'vue'
import type { DogBreed, DogEntity } from '@lyka/bab-api-contracts/src/bab'
import StepNavigation from '../StepNavigation.vue'
import WeightDog from './weight/WeightDog.vue'
import StepsContainer from '@/components/StepContainer.vue'
import { type WeightStep } from '@/steps/weight'

const props = defineProps<{
  step: WeightStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const disabled = computed(() => {
  return !props.step.valid.value || props.step.busy.value
})

const getDogBreeds = (dog: DogEntity): DogBreed[] => props.step.breeds.filter((b) => dog.breeds?.includes(b.slug))

const updateDogWeight = (dog: DogEntity, weight: number): void => {
  props.step.updateWeight(dog, weight)
}

const submit = (): void => {
  emits('next')
}
</script>

<template>
  <StepsContainer id="step-weight">
    <form :id="step.name" v-submit @submit.prevent="submit">
      <WeightDog
        v-for="(dog, index) in step.dogs"
        :key="index"
        :index="index"
        :dog="dog"
        :breeds="getDogBreeds(dog)"
        @update="(weight) => updateDogWeight(dog, weight)"
      />

      <StepNavigation submit :next-disabled="disabled" :form="step.name" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
