import { computed } from 'vue'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { useDogsStore } from '@/stores/dogs'
import { useTreatsStore } from '@/stores/treats'

const FUSSINESS_TOPPER_PRODUCT_NAME = 'fussiness_topper_pouch_75g_free' as const

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFreeFussyMealTopper = () => {
  const experiments = useExperiments()
  const dogs = useDogsStore().getDogs()
  const treatsStore = useTreatsStore()

  const productId = computed<number | undefined>(() => {
    const product = treatsStore.findProductByName(FUSSINESS_TOPPER_PRODUCT_NAME)

    return product?.id
  })

  const enabled = computed<boolean>(() => {
    const isExperimentEnabled = experiments.inExperiment('known-bab-free-topper')

    const hasSuitableDog = dogs.some((dog) => {
      const exists = productId.value !== undefined
      const isFussy: boolean = !!dog.fusinessLevel && ['choosy', 'fussy'].includes(dog.fusinessLevel.name)
      const isAllergicToBeef = dog.allergies.some((allergy) => allergy.name === 'beef')

      return exists && !isAllergicToBeef && isFussy
    })

    return hasSuitableDog && isExperimentEnabled
  })

  return {
    productId,
    enabled,
  }
}
