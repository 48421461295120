import z from 'zod'
import { computed } from 'vue'
import type { DogEntity, MealRecipe } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useGuestUserStep } from './user'
import { usePlanStep } from './plan'
import { useHealthIssuesStep } from './healthIssues'
import { StepName } from '@/stores/steps'
import { useUserStore } from '@/stores/user'
import { useDogsStore } from '@/stores/dogs'
import { useRecommendationStore } from '@/stores/recommendation'

const StepSchema = z.record(z.string().uuid(), z.array(z.string()).min(1))

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineRecipesStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()
  const userStore = useUserStore()
  const recommendationStore = useRecommendationStore()

  const valid = computed(() => {
    const allDogsSet = Object.keys(recommendationStore.selectedRecipes).length === dogsStore.dogs.length
    return allDogsSet && StepSchema.safeParse(recommendationStore.selectedRecipes).success
  })

  const getPreviousStep = (): IStep | undefined => {
    if (userStore.currentUser) {
      return useHealthIssuesStep()
    }

    return useGuestUserStep()
  }

  const getNextStep = (): IStep | undefined => {
    return usePlanStep()
  }

  const updateRecipes = async (dog: DogEntity, recipes: MealRecipe[]): Promise<void> => {
    await recommendationStore.updateDogRecipes(dog, recipes)
  }

  const load = async (): Promise<void> => {
    await recommendationStore.loadRecipes()
  }

  return {
    name: StepName.Recipes,
    busy,
    valid,
    dogs: dogsStore.dogs,
    breeds: dogsStore.breeds,
    healthIssues: dogsStore.healthIssues,
    allergens: dogsStore.foodAllergens,
    recipes: recommendationStore.mealRecipes,
    selectedRecipes: recommendationStore.selectedRecipes,
    load,
    updateRecipes,
    getPreviousStep,
    getNextStep,
  }
}

export type RecipesStep = ReturnType<typeof defineRecipesStep>

export const useRecipesStep = registerStep(defineRecipesStep)
