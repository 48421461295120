import { initContract } from '@ts-rest/core'
import z from 'zod'

/* eslint-disable @typescript-eslint/no-redeclare */

const c = initContract()

const ErrorResponse = z.object({
  error: z.string(),
})

const userAccessToken = z.string()

export const NonEmptyString = z.string().trim().min(1)

const AnimalId = NonEmptyString

export const HasMany = z.array(NonEmptyString)

export const Postcode = z.string().trim().length(4)

export const Gender = {
  Male: 'male',
  Female: 'female',
} as const

export type Gender = (typeof Gender)[keyof typeof Gender]

export const BodyType = {
  Underweight: 'slim',
  Average: 'perfect',
  Overweight: 'chunky',
} as const

export type BodyType = (typeof BodyType)[keyof typeof BodyType]

export const ActivityLevel = {
  High: 'ball_of_energy',
  Medium: 'loves_to_play',
  Low: 'chilled',
} as const

export type ActivityLevel = (typeof ActivityLevel)[keyof typeof ActivityLevel]

export const FussinessLevel = {
  High: 'very_fussy',
  Medium: 'choosy',
  Low: 'eats_anything',
} as const

export type FussinessLevel = (typeof FussinessLevel)[keyof typeof FussinessLevel]

export const MAX_DOG_AGE = 31

export const DogDateOfBirth = z
  .string()
  .date()
  .refine((data) => {
    const today = new Date()
    const birthday = new Date(data).getTime()

    const minBirthday = new Date().setFullYear(today.getFullYear() - MAX_DOG_AGE)

    if (birthday > today.getTime()) {
      return false
    }

    return minBirthday < birthday
  })

export const DogProperties = z.object({
  name: NonEmptyString,
  gender: z.enum([Gender.Male, Gender.Female]),
  activityLevel: z.enum([ActivityLevel.High, ActivityLevel.Medium, ActivityLevel.Low]),
  eatingHabits: z.enum([FussinessLevel.High, FussinessLevel.Medium, FussinessLevel.Low]),
  bodyType: z.enum([BodyType.Underweight, BodyType.Average, BodyType.Overweight]),
  dateOfBirth: DogDateOfBirth,
  dateOfBirthIsApproximate: z.boolean(),
  weight: z.coerce.number().multipleOf(0.1).positive().max(99),
  breeds: HasMany.max(2),
  previousFoods: HasMany,
  foodAllergies: HasMany,
  healthIssues: HasMany,
})

export const DogData = DogProperties.partial()

export type DogData = z.infer<typeof DogData>

type Identifiable<T extends object> = T & {
  identifier: string
}

type Slugged<T extends object> = T & {
  slug: string
}

export type DogEntity = Identifiable<DogData>

export type DogBreed = Slugged<{
  name: string
  adultWeightMaleMin: number
  adultWeightMaleMax: number
  adultWeightFemaleMin: number
  adultWeightFemaleMax: number
  adultAgeMonthsMax: number
}>

export type DogHealthIssue = Slugged<{
  name: string
}>

export type DogFoodAllergen = Slugged<{
  name: string
}>

export type DogFoodType = Slugged<{
  name: string
}>

export type LykaUser = Identifiable<{
  email: string
  firstName: string
  lastName: string
}>

export interface LoginData {
  userAccessToken: string
  userRefreshToken: string
}

export const MealRecipeStatus = {
  Neutral: 'neutral',
  Recommended: 'recommended',
  NotRecommended: 'not_recommended',
  Blocked: 'blocked',
} as const

export type MealRecipeStatus = (typeof MealRecipeStatus)[keyof typeof MealRecipeStatus]

export type MealRecipe = Identifiable<{
  key: string
  name?: string
  description: string
  productType: string
  status: MealRecipeStatus
  reason?: string
}>

export type ProductVariant = Identifiable<{
  productId: string
  key: string
  weight: number
}>

export interface FeedingPlanQuantity {
  quantity: number
  variant: ProductVariant
}

export interface FeedingPlan {
  gramsPerDay: number
  hidden: boolean
  feedingPlanType: string
  name: string
  price: number | null
  deliveryFrequencyWeeks: number
  quantities: FeedingPlanQuantity[]
}

export interface FeedingPlanSubscriptionData {
  type: string
  name: string
  productIds: Array<string>
  pouchSizeGrams: number
  gramsPerDay: number
  deliveryFrequencyWeeks: number
  totalPouches: number
}

export interface Cart {
  checkoutUrl: string
}

export interface DeliveryDate {
  date: Date
  day: string
  slots: Array<DeliveryDateSlot>
}

export interface DeliveryDateSlot {
  window: 'AM' | 'PM'
  dispatchDayDeltaInSeconds: number
  manifestDayDeltaInSeconds: number
  makeDayDeltaInSeconds: number
  packingDayDeltaInSeconds: number
  billingDayDeltaInSeconds: number
}

export interface DeliverySchedule {
  zoneId: number
  dates: Array<DeliveryDate>
}

export interface AuthData {
  userAccessToken: string
  userRefreshToken: string
}

export const babContract = c.router({
  getDogsForUser: {
    method: 'GET',
    path: '/animals/getDogsForUser',
    summary: 'Get animals for the current user',
    query: z.object({
      userAccessToken,
    }),
    responses: {
      200: c.type<DogEntity[]>(),
      401: ErrorResponse,
      500: ErrorResponse,
    },
  },

  getDog: {
    method: 'GET',
    path: '/animals/getDog',
    summary: 'Get dog by ID',
    query: z.object({
      animalId: AnimalId,
      userAccessToken: userAccessToken.optional(),
    }),
    responses: {
      200: c.type<DogEntity>(),
      404: ErrorResponse,
      500: ErrorResponse,
    },
  },

  createDog: {
    method: 'POST',
    path: '/animals/createDog',
    summary: 'Create a new animal',
    body: z.object({}).optional(),
    responses: {
      201: c.type<DogEntity>(),
      500: ErrorResponse,
    },
  },

  updateDog: {
    method: 'POST',
    path: '/animals/updateDog',
    summary: 'Update an animal',
    body: z.object({
      animalId: AnimalId,
      animal: DogData,
      userAccessToken: userAccessToken.optional(),
    }),
    responses: {
      200: c.type<DogEntity>(),
      401: ErrorResponse,
      422: ErrorResponse,
      404: ErrorResponse,
      500: ErrorResponse,
    },
  },

  getDogBreeds: {
    method: 'GET',
    path: '/animals/getDogBreeds',
    summary: 'Get the list of dog breeds',
    responses: {
      200: c.type<DogBreed[]>(),
      500: ErrorResponse,
    },
  },

  getDogHealthIssues: {
    method: 'GET',
    path: '/animals/getDogHealthIssues',
    summary: 'Get the list of dog health issues',
    responses: {
      200: c.type<DogHealthIssue[]>(),
      500: ErrorResponse,
    },
  },

  getDogFoodAllergens: {
    method: 'GET',
    path: '/animals/getDogFoodAllergens',
    summary: 'Get the list of dog food allergens',
    responses: {
      200: c.type<DogFoodAllergen[]>(),
      500: ErrorResponse,
    },
  },

  getDogFoodTypes: {
    method: 'GET',
    path: '/animals/getDogFoodTypes',
    summary: 'Get the list of dog food types',
    responses: {
      200: c.type<DogFoodType[]>(),
      500: ErrorResponse,
    },
  },

  associateAnimalWithUser: {
    method: 'POST',
    path: '/animals/associateAnimalWithUser',
    summary: 'Associate an animal with the current user based on the auth token',
    body: z.object({
      animalId: AnimalId,
      userAccessToken,
    }),
    responses: {
      200: z.object({
        success: z.boolean(),
      }),
    },
  },

  disassociateAnimalWithUser: {
    method: 'POST',
    path: '/animals/disassociateAnimalWithUser',
    summary: 'Disassociate an animal with the current user based on the auth token',
    body: z.object({
      animalId: AnimalId,
      userAccessToken,
    }),
    responses: {
      200: z.object({
        success: z.boolean(),
      }),
    },
  },

  getDogMealRecipes: {
    method: 'GET',
    path: '/animals/getDogMealRecipes',
    summary: 'Get a list of meal recipes for specified dog',
    query: z.object({
      animalId: z.string().uuid(),
    }),
    responses: {
      200: c.type<MealRecipe[]>(),
      500: ErrorResponse,
    },
  },

  getDogFeedingPlans: {
    method: 'POST',
    path: '/animals/getDogFeedingPlans',
    summary: 'Get a list of feeding plans for specified dog',
    body: z.object({
      animalId: z.string().uuid(),
      recipes: z.array(NonEmptyString).optional(),
    }),
    responses: {
      200: c.type<FeedingPlan[]>(),
      500: ErrorResponse,
    },
  },

  getSuburbServiceable: {
    method: 'GET',
    path: '/courier/getSuburbServiceable',
    summary: 'Check if a suburb is serviceable',
    query: z.object({
      postcode: Postcode,
      suburb: NonEmptyString,
    }),
    responses: {
      200: z.object({
        serviceable: z.boolean(),
      }),
      404: ErrorResponse,
      500: ErrorResponse,
    },
  },

  getSuburbDeliverySchedule: {
    method: 'GET',
    path: '/courier/getSuburbDeliverySchedule',
    summary: 'Get a suburbs delivery schedule',
    query: z.object({
      postcode: Postcode,
      suburb: NonEmptyString,
    }),
    responses: {
      200: c.type<DeliverySchedule>(),
      404: ErrorResponse,
      500: ErrorResponse,
    },
  },

  createGuestUser: {
    method: 'POST',
    path: '/users/createGuestUser',
    summary: 'Create a guest user',
    body: z.object({
      email: z.string().email(),
      firstName: NonEmptyString,
    }),
    responses: {
      201: c.type<AuthData>(),
      400: ErrorResponse,
    },
  },

  reauthenticate: {
    method: 'POST',
    path: '/users/reauthenticate',
    summary: 'Reauthenticate the user',
    body: z.object({
      // The old token
      userAccessToken: NonEmptyString,
      userRefreshToken: NonEmptyString,
    }),
    responses: {
      201: c.type<AuthData>(),
      401: ErrorResponse,
    },
  },

  getCurrentUser: {
    method: 'GET',
    path: '/users/getCurrentUser',
    summary: 'Get the current user',
    query: z.object({
      userAccessToken,
    }),
    responses: {
      200: c.type<LykaUser>(),
      401: ErrorResponse,
    },
  },

  authenticateGuestUser: {
    method: 'POST',
    path: '/users/authenticateGuestUser',
    summary: 'Authenticate a guest user with their email address',
    body: z.object({
      email: z.string().email(),
    }),
    responses: {
      200: c.type<LoginData>(),
      401: ErrorResponse,
    },
  },

  checkout: {
    method: 'POST',
    path: '/ecommerce/checkout',
    summary: 'Create a subscription',
    body: z.object({
      userAccessToken,
      suburb: NonEmptyString,
      postcode: Postcode,
      animals: z
        .array(
          z.object({
            animalId: AnimalId,
            recipes: z.array(NonEmptyString).optional(),
            feedingPlanType: NonEmptyString,
          }),
        )
        .min(1),
    }),
    responses: {
      201: c.type<{
        checkoutUrl: string
      }>(),
      401: ErrorResponse,
    },
  },
})
