import { defineStore } from 'pinia'
import { reactive } from 'vue'
import type { DogEntity, FeedingPlan, MealRecipe } from '@lyka/bab-api-contracts/src/bab'
import { useDogsStore } from './dogs'
import { useClient } from '@/services/useClient'
import { isSuitableRecipe, isTherapeuticRecipe } from '@/models/Recipe'
import { MealPlanType } from '@/models/MealPlan'

interface Dependencies {
  client?: typeof useClient
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createRecommendationStore = ({ client = useClient }: Dependencies = {}) => {
  return defineStore('recommendation-phoenix', () => {
    const dogStore = useDogsStore()

    const mealRecipes = reactive<Record<string, MealRecipe[]>>({})
    const selectedRecipes = reactive<Record<string, string[]>>({})

    const feedingPlans = reactive<Record<string, FeedingPlan[]>>({})
    const selectedPlan = reactive<Record<string, string>>({})

    const loadRecipes = async (): Promise<void> => {
      Object.assign(mealRecipes, {})

      const data = await Promise.all(
        dogStore.dogs.map(async (dog) => {
          const recipes = await client().bab.getDogMealRecipes({ query: { animalId: dog.identifier } })
          return { dog, recipes: recipes.body }
        }),
      )

      data.forEach(({ dog, recipes }) => {
        if (Array.isArray(recipes)) {
          mealRecipes[dog.identifier] = recipes
        }
      })

      const hasSelections = Object.keys(selectedRecipes).length > 0

      if (!hasSelections) {
        dogStore.dogs.forEach((dog) => {
          const meals = mealRecipes[dog.identifier]

          if (meals) {
            selectedRecipes[dog.identifier] = meals
              ?.filter((recipe) => isSuitableRecipe(recipe) && !isTherapeuticRecipe(recipe))
              ?.map(({ key }) => key)
          }
        })
      }
    }

    const loadPlans = async (): Promise<void> => {
      Object.assign(feedingPlans, {})

      const data = await Promise.all(
        dogStore.dogs.map(async (dog) => {
          const recipes = selectedRecipes[dog.identifier]
          const plans = await client().bab.getDogFeedingPlans({ body: { animalId: dog.identifier, recipes } })

          return { dog, plans: plans.body }
        }),
      )

      data.forEach(({ dog, plans }) => {
        if (Array.isArray(plans)) {
          feedingPlans[dog.identifier] = plans
        }
      })

      const hasSelections = Object.keys(selectedPlan).length > 0

      if (!hasSelections) {
        dogStore.dogs.forEach((dog) => {
          selectedPlan[dog.identifier] = MealPlanType.Full
        })
      }
    }

    const updateDogRecipes = async (dog: DogEntity, recipes: MealRecipe[]): Promise<void> => {
      selectedRecipes[dog.identifier] = recipes.map((recipe) => recipe.key)
    }

    const updateDogPlan = async (dog: DogEntity, plan: FeedingPlan): Promise<void> => {
      selectedPlan[dog.identifier] = plan.feedingPlanType
    }

    const getPlanForDog = (identifier: string): string | undefined => {
      return selectedPlan[identifier]
    }

    const getRecipesForDog = (identifier: string): string[] => {
      return selectedRecipes[identifier] ?? []
    }

    return {
      mealRecipes,
      selectedRecipes,
      feedingPlans,
      selectedPlan,
      loadPlans,
      loadRecipes,
      updateDogPlan,
      updateDogRecipes,
      getRecipesForDog,
      getPlanForDog,
    }
  })
}

export const useRecommendationStore = createRecommendationStore()
