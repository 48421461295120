type ErrorResult<E = Error> = [undefined, E]
type SuccessResult<T = any> = [T, undefined]

// Forces explicit error-handling by returning a tuple with the result and a possible error.
// This is similar to Go's approach and forces errors to be handled rather than
// optionally wrapping everything in a try/catch.
export type Result<T = any, E = Error> = ErrorResult<E> | SuccessResult<T>

// As above, but for promises.
export type PromiseResult<T, E> = Promise<Result<T, E>>

// Create a successful or failed result
export const successResult = <D, _>(data: D): SuccessResult<D> => [data, undefined]
export const failureResult = <_, E>(error: E): ErrorResult<E> => [undefined, error]
