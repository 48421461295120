import { ActivityLevel, type DogBreed, FussinessLevel } from '@lyka/bab-api-contracts/src/bab'
import { birthdayToAge } from '@/utils/birthdayConversion'

export enum AgeType {
  Puppy = 'puppy',
  Adult = 'adult',
}

export const getDogAgeType = (dateOfBirth: string, breeds: DogBreed[]): AgeType => {
  const { years, months } = birthdayToAge(dateOfBirth)
  const dogAgeMonths = years * 12 + months

  const adultAgeMonthsMax = breeds.reduce((sum, breed) => sum + breed.adultAgeMonthsMax, 0) / (breeds.length || 1)

  return dogAgeMonths >= adultAgeMonthsMax ? AgeType.Adult : AgeType.Puppy
}

export const getActivityLevelTitle = (activityLevel?: ActivityLevel): string => {
  switch (activityLevel) {
    case ActivityLevel.Low: {
      return 'Chilled out'
    }
    case ActivityLevel.Medium: {
      return 'Loves to play'
    }
    case ActivityLevel.High: {
      return 'Ball of energy'
    }
    default: {
      return ''
    }
  }
}

export const getFussinessLevelTitle = (fussinessLevel?: FussinessLevel): string => {
  switch (fussinessLevel) {
    case FussinessLevel.Low: {
      return 'Will eat anything'
    }
    case FussinessLevel.Medium: {
      return 'Can be choosy'
    }
    case FussinessLevel.High: {
      return 'Seriously fussy'
    }
    default: {
      return ''
    }
  }
}

export const getFussinessLevelDescription = (fussinessLevel?: FussinessLevel): string => {
  switch (fussinessLevel) {
    case FussinessLevel.Low: {
      return 'The see-food diet'
    }
    case FussinessLevel.Medium: {
      return 'Variety is the spice of life'
    }
    case FussinessLevel.High: {
      return 'Only the finest wines and dines'
    }
    default: {
      return ''
    }
  }
}
