<script setup lang="ts">
import type { DogEntity } from '@lyka/bab-api-contracts/src/bab'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import BreedDogBreeds from './breed/BreedDogBreeds.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { BreedStep } from '@/steps/breed'

const props = defineProps<{
  step: BreedStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const submit = (): void => {
  emits('next')
}

const updateBreeds = (dog: DogEntity, breeds: string[]): void => {
  props.step.updateBreeds(dog, breeds)
}
</script>

<template>
  <StepsContainer id="step-breed">
    <form :id="step.name" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in step.dogs" :key="index">
        <MiniHr v-if="index > 0" />
        <BreedDogBreeds :dog="dog" :breeds="step.breeds" @update="updateBreeds" />
      </div>

      <StepNavigation submit :next-disabled="!step.valid.value" :form="step.name" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
