import { defineStore } from 'pinia'
import { computed, ref, shallowRef } from 'vue'
import { useNameStep } from '@/steps/name'
import type { IStep } from '@/steps/step'
import { getURLParam } from '@/utils/getURLParam'

export enum StepName {
  Name = 'name',
  Location = 'location',
  UnservicedLocation = 'unserviced-location',
  Gender = 'gender',
  Breed = 'breed',
  Age = 'age',
  Weight = 'weight',
  BodyShape = 'shape',
  Activity = 'activity',
  Fussiness = 'fussiness',
  PreviouslyFed = 'previously-fed',
  Allergies = 'allergies',
  // @deprecated
  HasIllnesses = 'illness',

  // @deprecated
  Illnesses = 'illnesses',

  HealthIssues = 'illnesses',

  // @deprecated
  ContactDetails = 'contact',
  User = 'contact',
  NoRecipes = 'no-recipes',
  Recipes = 'recipes',
  Plan = 'plan',
  Treats = 'treats',
  Checkout = 'checkout',
  CheckoutFreeTreats = 'free-treats',
}

export enum StepChangeDirection {
  Forward = 'forward',
  Backward = 'backward',
}

export const STEP_NAMES = Object.values(StepName)

const isStepName = (stepName: unknown): stepName is StepName => {
  return STEP_NAMES.includes(stepName as StepName)
}

export const useStepsStore = defineStore('steps-phoenix', () => {
  const FIRST_STEP = useNameStep

  const getLastStepName = (): StepName => {
    let lastStep: IStep = FIRST_STEP()

    while (lastStep.getNextStep()) {
      lastStep = lastStep.getNextStep()!
    }

    return lastStep.name
  }

  const currentStep = shallowRef<IStep>(FIRST_STEP())

  const goToStep = async (name: StepName): Promise<void> => {
    let lastStep: IStep | undefined = FIRST_STEP()

    while (lastStep?.name !== name && lastStep?.getNextStep()) {
      await lastStep.load?.()

      const valid = lastStep.valid.value

      if (valid) {
        await lastStep.submit?.()
        lastStep = lastStep.getNextStep()
      } else {
        break
      }
    }

    currentStep.value = lastStep ?? FIRST_STEP()
  }

  const isFirstStep = computed(() => {
    return currentStep.value.name === FIRST_STEP().name
  })

  const initialize = async (): Promise<void> => {
    // Check to see if we have a step param in the URL
    const stepParam = getURLParam('step')

    // If we do then skip to that step, otherwise start at the first step
    const stepName = isStepName(stepParam) ? stepParam : undefined

    if (!stepName) {
      return
    }

    await goToStep(stepName)
  }

  // The direction of the last step change
  const direction = ref(StepChangeDirection.Forward)
  const currentStepName = computed(() => currentStep.value.name)

  const goToPreviousStep = (): void => {
    const prevStep = currentStep.value.getPreviousStep()

    if (!currentStep.value.busy.value && prevStep) {
      currentStep.value = prevStep
    }
  }

  const goToNextStep = async (): Promise<void> => {
    if (currentStep.value.valid.value === false) {
      return
    }

    // Submit the current step if necessary. This is useful for steps that have async behavior (eg. verifying a postcode is serviced)
    await currentStep.value.submit?.()

    const nextStep = currentStep.value.getNextStep()

    if (currentStep.value.valid.value && !currentStep.value.busy.value && nextStep) {
      currentStep.value = nextStep
    }
  }

  return {
    currentStep,
    currentStepName,
    direction,
    isFirstStep,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    getLastStepName,
    isStepName,
    initialize,
  }
})
