export enum MealPlanType {
  Full = 'full_bowl',
  Half = 'half_bowl',
  Starter = 'starter_box',
  Trial = 'trial_box',
}

export const TRIAL_PLAN_POUCH_SIZE_GRAMS = 125
export const TRIAL_PLAN_POUCH_QUANTITY = 14

export const getMealPlanTypeById = (id: number): MealPlanType | undefined => {
  switch (id) {
    case 1: {
      return MealPlanType.Full
    }
    case 2: {
      return MealPlanType.Half
    }
    case 3: {
      return MealPlanType.Starter
    }
  }

  return undefined
}
