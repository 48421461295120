import z from 'zod'
import { computed, reactive } from 'vue'
import { DogProperties } from '@lyka/bab-api-contracts/src/bab'
import type { BodyType, DogEntity } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useWeightStep } from './weight'
import { useActivityStep } from './activity'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'

const StepSchema = z
  .array(
    z.object({
      bodyType: DogProperties.shape.bodyType,
    }),
  )
  .min(1)

export type BodyShapeStepData = z.infer<typeof StepSchema>

export interface DogBodyShapeData {
  identifier: string
  bodyType: BodyType | undefined
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineBodyShapeStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const data = reactive<DogBodyShapeData[]>(
    dogsStore.dogs.map(({ identifier, bodyType }) => ({
      identifier,
      bodyType,
    })),
  )

  const valid = computed(() => {
    return StepSchema.safeParse(data).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useWeightStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useActivityStep()
  }

  const updateBodyShape = async (dog: DogEntity, bodyType: DogEntity['bodyType']): Promise<void> => {
    const dogData = data.find((d) => d.identifier === dog.identifier)

    if (!dogData) {
      return
    }

    Object.assign(dogData, { bodyType })

    if (!valid.value) {
      return
    }

    await dogsStore.updateDog(dog, { bodyType })
  }

  return {
    name: StepName.BodyShape,
    busy,
    valid,
    dogs: dogsStore.dogs,
    updateBodyShape,
    getPreviousStep,
    getNextStep,
  }
}

export type BodyShapeStep = ReturnType<typeof defineBodyShapeStep>

export const useBodyShapeStep = registerStep(defineBodyShapeStep)
