<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl } from '@lyka/utils'
import { FussinessLevel } from '@lyka/bab-api-contracts/src/bab'
import OptionGroup from '../common/OptionGroup.vue'
import OptionTile from '../common/OptionTile.vue'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { FussinessStep } from '@/steps/fussiness'
import { getFussinessLevelDescription, getFussinessLevelTitle } from '@/models/Dog'

const props = defineProps<{
  step: FussinessStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const fussinessLevels = Object.values(FussinessLevel) as FussinessLevel[]

const fussinessImageURL = (name: string): string => {
  return assetUrl(`images/steps/fussiness/${name}.svg`)
}

const disabled = computed(() => !props.step.valid.value)
</script>

<template>
  <StepsContainer id="step-fussiness" size="lg">
    <form id="form-fussiness" v-submit @submit.prevent="emit('next')">
      <div v-for="(dog, index) in step.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading> Is {{ dog.name }} a fussy eater? </StepHeading>

        <OptionGroup>
          <OptionTile
            v-for="fussinessLevel in fussinessLevels"
            :key="fussinessLevel"
            :model-value="dog.eatingHabits"
            input-type="radio"
            :input-value="fussinessLevel"
            :title="getFussinessLevelTitle(fussinessLevel)"
            :description="getFussinessLevelDescription(fussinessLevel)"
            @update:model-value="(value) => props.step.updateFussiness(dog, value as FussinessLevel)"
          >
            <template #icon>
              <img :src="fussinessImageURL(fussinessLevel)" />
            </template>
          </OptionTile>
        </OptionGroup>
      </div>

      <StepNavigation submit :next-disabled="disabled" form="form-fussiness" @previous="emit('previous')" />
    </form>
  </StepsContainer>
</template>
