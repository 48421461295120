import z from 'zod'
import { computed, ref } from 'vue'
import type { DogEntity, FeedingPlan } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useRecipesStep } from './recipes'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'
import { useRecommendationStore } from '@/stores/recommendation'
import { useEcommerceStore } from '@/stores/ecommerce'

const StepSchema = z.record(z.string().uuid(), z.string())

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const definePlanStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()
  const recommendationStore = useRecommendationStore()
  const ecommerceStore = useEcommerceStore()

  const submitting = ref(false)

  const valid = computed(() => {
    const allDogsSet = Object.keys(recommendationStore.selectedPlan).length === dogsStore.dogs.length
    return allDogsSet && StepSchema.safeParse(recommendationStore.selectedPlan).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useRecipesStep()
  }

  const getNextStep = (): IStep | undefined => {
    return undefined
  }

  const updateDogPlan = async (dog: DogEntity, plan: FeedingPlan): Promise<void> => {
    await recommendationStore.updateDogPlan(dog, plan)
  }

  const load = async (): Promise<void> => {
    await recommendationStore.loadPlans()
  }

  const checkout = async (): Promise<void> => {
    if (submitting.value) {
      return
    }

    submitting.value = true

    try {
      const result = await ecommerceStore.checkout()

      if (result) {
        location.href = result
      }
    } finally {
      submitting.value = false
    }
  }

  return {
    name: StepName.Plan,
    busy,
    submitting,
    valid,
    dogs: dogsStore.dogs,
    plans: recommendationStore.feedingPlans,
    selectedPlan: recommendationStore.selectedPlan,
    recipes: recommendationStore.mealRecipes,
    selectedRecipes: recommendationStore.selectedRecipes,
    checkout,
    load,
    updateDogPlan,
    getPreviousStep,
    getNextStep,
  }
}

export type PlanStep = ReturnType<typeof definePlanStep>

export const usePlanStep = registerStep(definePlanStep)
