import { z } from 'zod'

const envSchema = z.object({
  VITE_LYKA_URL: z.string(),
  VITE_PUBLIC_PATH: z.string().optional(),
  VITE_LYKA_API_URL: z.string(),
  VITE_STRIPE_API_KEY: z.string(),
  VITE_SEGMENT_PHOENIX_WRITE_KEY: z.string(),
  VITE_SENTRY_DSN: z.string(),
  VITE_SENTRY_ENV: z.string(),
  VITE_ADDRESS_FINDER_KEY: z.string(),
  VITE_TRUSTPILOT_BUSINESSUNIT_ID: z.string(),
  VITE_TRUSTPILOT_BUSINESSUNIT_NAME: z.string(),
  VITE_GTM_ID: z.string(),
  VITE_CUSTOMER_PORTAL_URL: z.string(),
  VITE_FLAGSMITH_ENVIRONMENT_KEY: z.string(),
  VITE_FRONT_CHAT_ID: z.string(),
  VITE_RECAPTCHA_SITE_KEY: z.string(),
  VITE_EVENT_GATEWAY_URL: z.string(),
  VITE_ENABLE_CRAWLERS: z.string().optional(),
  VITE_HONEYCOMB_API_KEY: z.string().optional(),
  VITE_STRIPE_PAYMENT_METHOD_CONFIGURATION_ID: z.string(),
  VITE_STRIPE_PAYMENT_METHOD_CONFIGURATION_ID_APPLE_PAY: z.string(),
})

const env = envSchema.parse(import.meta.env ?? process.env)

export default env
