<script setup lang="ts">
import { computed } from 'vue'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import HealthIssuesListDog from './healthIssues/HealthIssuesListDog.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { HealthIssuesStep } from '@/steps/healthIssues'

const props = defineProps<{
  step: HealthIssuesStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const disabled = computed(() => !props.step.valid)

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('next')
}
</script>

<template>
  <StepsContainer id="step-health-issues">
    <form :id="step.name" v-submit class="tw-space-y-8" @submit.prevent="submit">
      <HealthIssuesListDog
        v-for="(dog, index) in step.dogs"
        :key="index"
        :health-issues="step.healthIssues"
        :dog="dog"
        @update="(dog, healthIssues) => step.updateHealthIssues(dog, healthIssues)"
      />

      <StepNavigation submit :form="step.name" :next-disabled="disabled" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
