<script setup lang="ts">
import { computed } from 'vue'
import StepNavigation from '../StepNavigation.vue'
import AgeDog from './age/AgeDog.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { AgeStep } from '@/steps/age'

const props = defineProps<{
  step: AgeStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const disabled = computed(() => {
  return !props.step.valid.value
})
</script>

<template>
  <StepsContainer id="step-age">
    <form :id="props.step.name" v-submit class="tw-space-y-4" @submit.prevent="emit('next')">
      <AgeDog
        v-for="(dog, index) in props.step.dogs"
        :key="dog.identifier"
        :dog="dog"
        :index="index"
        @update="(dob, approx) => step.updateDateOfBirth(dog, dob, approx)"
      />

      <StepNavigation submit :next-disabled="disabled" :form="props.step.name" @previous="emit('previous')" />
    </form>
  </StepsContainer>
</template>
