<script setup lang="ts">
import { computed } from 'vue'
import PlanInclusion from './PlanInclusion.vue'
import { type Recipe, isTherapeuticRecipe } from '@/models/Recipe'
import { MealPlanType } from '@/models/MealPlan'

const props = withDefaults(
  defineProps<{
    mealPlanType?: MealPlanType | null
    recipes: Pick<Recipe, 'name'>[]
  }>(),
  {
    recipes: () => [],
  },
)

const isTrialPlan = computed<boolean>(() => {
  return props.mealPlanType === MealPlanType.Trial
})

const includesGoToGutHelper = computed<boolean>(() => {
  return !isTrialPlan.value
})

const includesHypoProGuide = computed<boolean>(() => {
  return !isTrialPlan.value && !!props.recipes.length && props.recipes.every(isTherapeuticRecipe)
})

const includesGamePlan = computed<boolean>(() => {
  return !isTrialPlan.value && !includesHypoProGuide.value
})

const includesTrialMeals = computed<boolean>(() => {
  return isTrialPlan.value
})
</script>

<template>
  <div class="tw-space-y-8 lg:tw-space-y-12">
    <h2 class="tw-h2">
      <slot name="heading">
        Included in your
        <span class="tw-text-green tw-whitespace-nowrap">First Box</span>
      </slot>
    </h2>

    <div class="tw-grid tw-justify-center lg:tw-grid-flow-col lg:tw-auto-cols-fr tw-gap-8">
      <PlanInclusion
        v-if="includesTrialMeals"
        image="images/steps/plans/starter/pouches.webp"
        alt="A bundle of packaged Lyka meal pouches"
        title="Your taster pouches"
        description="Freshly prepared, lightly cooked and bursting with a balanced mix of protein, wholefoods and vitamins."
      />

      <PlanInclusion
        v-else
        image="images/steps/plans/starter/pouches.webp"
        alt="A bundle of packaged Lyka meal pouches"
        title="Your personalised meals"
        description="Freshly prepared, lightly cooked and bursting with a balanced mix of protein, wholefoods and vitamins."
      />

      <PlanInclusion
        v-if="includesGoToGutHelper"
        image="images/steps/plans/starter/probiotic.webp"
        alt="A container of Lyka's probiotic Go-To Gut Helper"
        title="Go-To Gut Helper"
        description="Probiotic powder to support a smooth transition to fresh feeding."
      />

      <PlanInclusion
        v-if="includesHypoProGuide"
        image="images/steps/plans/hypoallergenic-pro-guide.jpg"
        alt="Hypoallergenic Pro Guide"
        title="Hypoallergenic Pro Guide"
        description="Tips for a smooth transition to a hypoallergenic diet."
      />

      <PlanInclusion
        v-if="includesGamePlan"
        image="images/steps/plans/starter/board.webp"
        alt="Lyka Transition Game Plan"
        title="Lyka Transition Game Plan"
        description="Your 28-day guide for a smooth transition to fresh food."
      />
    </div>
  </div>
</template>
