<script setup lang="ts">
import { computed } from 'vue'
import StepNavigation from '../StepNavigation.vue'
import StepsContainer from '@/components/StepContainer.vue'
import PreviouslyFedDog from '@/components/steps/previouslyFed/PreviouslyFedDog.vue'
import type { PreviouslyFedStep } from '@/steps/phoenix/previouslyFed'

const props = defineProps<{
  step: PreviouslyFedStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const disabled = computed(() => !props.step.valid.value)
</script>

<template>
  <StepsContainer>
    <form id="formPreviousRecipes" v-submit @submit.prevent="emit('next')">
      <div class="tw-space-y-12">
        <PreviouslyFedDog
          v-for="(dog, index) in step.dogs"
          :key="index"
          :dog="dog"
          :dog-index="index"
          :food-types="step.foodTypes"
          @update="(foods) => step.updatePreviousFoods(dog, foods)"
        />
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formPreviousRecipes" @previous="emit('previous')" />
    </form>
  </StepsContainer>
</template>
