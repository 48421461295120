import z from 'zod'
import { computed } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useGenderStep } from './gender'
import { useAgeStep } from './age'

import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/phoenix/dogs'

const StepSchema = z
  .array(
    z.object({
      breeds: DogProperties.shape.breeds,
    }),
  )
  .min(1)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineBreedStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useGenderStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useAgeStep()
  }

  const updateBreeds = async (dog: DogEntity, breeds: DogEntity['breeds']): Promise<void> => {
    await dogsStore.updateDog(dog, { breeds })
  }

  return {
    name: StepName.Breed,
    busy,
    valid,
    dogs: dogsStore.dogs,
    breeds: dogsStore.breeds,
    updateBreeds,
    getPreviousStep,
    getNextStep,
  }
}

export type BreedStep = ReturnType<typeof defineBreedStep>

export const useBreedStep = registerStep(defineBreedStep)
