import z from 'zod'
import { computed } from 'vue'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { usePreviouslyFedStep } from './previouslyFed'
import { useHealthIssuesStep } from './healthIssues'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'

const StepSchema = z
  .array(
    z.object({
      foodAllergies: DogProperties.shape.foodAllergies,
    }),
  )
  .min(1)

type FoodAllergies = z.infer<typeof DogProperties.shape.foodAllergies>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineAllergiesStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return usePreviouslyFedStep()
  }

  const getNextStep = (): IStep | undefined => {
    return useHealthIssuesStep()
  }

  const updateFoodAllergies = async (dog: DogEntity, foodAllergies: FoodAllergies): Promise<void> => {
    await dogsStore.updateDog(dog, { foodAllergies })
  }

  return {
    name: StepName.Allergies,
    busy,
    valid,
    dogs: dogsStore.dogs,
    foodAllergens: dogsStore.foodAllergens,
    updateFoodAllergies,
    getPreviousStep,
    getNextStep,
  }
}

export type AllergiesStep = ReturnType<typeof defineAllergiesStep>

export const useAllergiesStep = registerStep(defineAllergiesStep)
