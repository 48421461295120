<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaFieldList, LykaInput } from '@lyka/ui'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import { z } from 'zod'
import StepContainer from '../StepContainer.vue'
import StepNavigation from '../StepNavigation.vue'
import ContactBenefits from '@/components/steps/contact/ContactBenefits.vue'
import type { GuestUserStep } from '@/steps/user'

const props = defineProps<{
  step: GuestUserStep
}>()

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const Schema = z.object({
  email: z.string().email(),
  firstName: z.string().trim().min(1),
})

const submitting = ref(false)
const email = ref<string>(props.step.email.value ?? '')
const firstName = ref<string>(props.step.firstName.value ?? '')

const heading = computed<string>(() => {
  const [first, second] = props.step.dogs

  if (second || !first) {
    return "Your puppers' tailored meal plans are ready"
  }

  return `${first.name}'s tailored meal plan is ready!`
})

const disabled = computed(() => !props.step.valid || submitting.value)

const submit = async ({ target }: Event): Promise<void> => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  const result = Schema.safeParse({
    email: email.value,
    firstName: firstName.value,
  })

  if (!result.success) {
    return
  }

  try {
    submitting.value = true
    await props.step.createGuestUser({
      email: result.data.email,
      firstName: result.data.firstName,
    })
    emits('next')
  } finally {
    submitting.value = false
  }

  // Now that we have the user's email, we can load the 'known user' experiments
  // await useKnownUserExperiments().load()
}
</script>

<template>
  <StepContainer id="step-user" size="lg" center>
    <div class="tw-flex 2xl:tw-items-center tw-gap-10 tw-flex-col-reverse lg:tw-flex-row">
      <ContactBenefits />
      <div class="tw-flex-1 tw-text-left">
        <div class="tw-max-w-sm tw-mx-auto">
          <div class="tw-flex tw-mb-8 sm:tw-mb-12 tw-justify-center">
            <img
              src="@/assets/images/steps/contact-details/box.svg"
              alt="A Lyka box exploding with delicious dog food"
              width="327"
              height="179"
              class="tw-h-32 sm:tw-h-40"
            />
          </div>

          <form id="form-user" v-submit class="tw-space-y-6 sm:tw-space-y-8" @submit.prevent="submit">
            <div class="tw-space-y-2 sm:tw-space-y-8">
              <h2 id="contact-details-heading" class="tw-h2">
                {{ heading }}
              </h2>
              <p>Enter your details to see your tailored plan and pricing</p>
            </div>

            <div class="tw-space-y-8">
              <LykaFieldList>
                <LykaInput
                  v-model.trim="firstName"
                  name="first-name"
                  placeholder="First name"
                  required
                  error-message="Your first name is required"
                  autocapitalize
                />
                <LykaInput
                  v-model.trim="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  required
                  error-message="Your email is required"
                />
              </LykaFieldList>

              <!-- <MarketingConsentCheckbox v-model="data.user.marketingCommunication" /> -->
            </div>

            <StepNavigation
              submit
              :next-disabled="disabled"
              form="form-user"
              :next-loading="submitting"
              @previous="emits('previous')"
            />
          </form>
        </div>
      </div>
    </div>
  </StepContainer>
</template>
