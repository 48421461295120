export const birthdayToAge = (birthday?: string | Date): { years: number; months: number } => {
  if (!birthday) {
    return { years: 0, months: 0 }
  }

  const today = new Date()
  const bornDate = new Date(birthday)
  const ageMonths = today.getMonth() - bornDate.getMonth()
  const ageYears = today.getFullYear() - bornDate.getFullYear()

  if (ageYears < 0) {
    return { years: 0, months: 0 }
  }

  if (ageMonths < 0) {
    return { years: ageYears - 1, months: 12 + ageMonths }
  } else {
    return { years: ageYears, months: ageMonths }
  }
}

export const ageToBirthday = (age: {
  years: number
  months: number
}): { bornYear: number; bornMonth: number; bornDay: null | number } => {
  const today = new Date()
  const totalMonthsToday = today.getFullYear() * 12 + today.getMonth() + 1
  const totalMonthsAge = (age.years ?? 0) * 12 + (age.months ?? 0)
  const bornYear = Math.floor((totalMonthsToday - totalMonthsAge) / 12)
  const bornMonth = (totalMonthsToday - totalMonthsAge) % 12
  if (bornMonth === 0) {
    // Handle special case that born month is equal to the current month
    return { bornYear: bornYear - 1, bornMonth: 12, bornDay: null }
  } else {
    return { bornYear, bornMonth, bornDay: null }
  }
}
