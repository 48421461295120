import { defineStore } from 'pinia'
import { computed, reactive, watchEffect } from 'vue'
import { unique } from 'remeda'
import type { DogBreed, DogEntity, DogFoodAllergen, DogFoodType, DogHealthIssue } from '@lyka/bab-api-contracts/src/bab'
import { useStateStore } from './state'
import { useAuthStore } from './auth'
import { useClient } from '@/services/useClient'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createDogsStore = () => {
  return defineStore('dogs-phoenix', () => {
    const authStore = useAuthStore()
    const stateStore = useStateStore()
    const client = useClient()

    interface State {
      loaded: boolean

      dogs: DogEntity[]
      breeds: DogBreed[]
      healthIssues: DogHealthIssue[]
      foodAllergens: DogFoodAllergen[]
      foodTypes: DogFoodType[]
    }

    const state = reactive<State>({
      loaded: false,
      dogs: [],
      breeds: [],
      healthIssues: [],
      foodAllergens: [],
      foodTypes: [],
    })

    const $reset = (): void => {
      state.dogs = []
    }

    watchEffect(() => {
      if (state.loaded) {
        const identifiers = unique(state.dogs.map((dog) => dog.identifier))

        stateStore.setProperty('dogs', identifiers)
      }
    })

    const orderedDogs = computed(() => {
      return state.dogs.sort((a, b) => a.identifier.localeCompare(b.identifier))
    })

    // We don't actually need to destroy the dog
    const removeDog = async (id: string): Promise<void> => {
      const index = state.dogs.findIndex((dog) => dog.identifier === id)

      if (index > -1) {
        state.dogs.splice(index, 1)
      }

      const userAccessToken = authStore.getAccessToken()

      if (userAccessToken) {
        await client.bab.disassociateAnimalWithUser({ body: { animalId: id, userAccessToken } })
      }
    }

    const associateDog = async (dog: DogEntity): Promise<void> => {
      const userAccessToken = authStore.getAccessToken()

      if (!userAccessToken) {
        return
      }

      await client.bab.associateAnimalWithUser({ body: { animalId: dog.identifier, userAccessToken } })
    }

    const associateDogs = async (): Promise<void> => {
      const userAccessToken = authStore.getAccessToken()

      if (!userAccessToken) {
        throw new Error('User is not authenticated')
      }

      const requests = []

      for (const dog of state.dogs) {
        requests.push(associateDog(dog))
      }

      await Promise.allSettled(requests)
    }

    const createDog = async (): Promise<DogEntity | undefined> => {
      const response = await client.bab.createDog({
        body: {},
      })

      if (response.status === 201) {
        state.dogs.push(response.body)

        // If the user is authenticated, associate the dog with the user
        await associateDog(response.body)

        return response.body
      }
    }

    const updateDog = async (dog: DogEntity, { ...props }: Partial<DogEntity>): Promise<void> => {
      Object.assign(dog, props)

      await client.bab.updateDog({
        body: {
          animalId: dog.identifier,
          animal: props,
        },
      })
    }

    // If the user is authenticated then load the dogs from the server
    const loadDogsFromServer = async (): Promise<DogEntity[]> => {
      const userAccessToken = authStore.getAccessToken()

      if (userAccessToken) {
        const response = await client.bab.getDogsForUser({
          query: {
            userAccessToken,
          },
        })

        if (response.status === 200) {
          return response.body
        }
      }

      return []
    }

    // Load the dogs from localStorage
    const loadDogsFromClient = async (): Promise<DogEntity[]> => {
      const ids = stateStore.getProperty('dogs')

      const animals: DogEntity[] = []

      const promises = ids.map(async (id) => {
        const response = await client.bab.getDog({
          query: {
            animalId: id,
          },
        })

        if (response.status === 200) {
          animals.push(response.body)
        }
      })

      await Promise.all(promises)

      return animals
    }

    const loadDogs = async (): Promise<void> => {
      const dogsFromServer = await loadDogsFromServer()

      if (dogsFromServer.length) {
        state.dogs.length = 0
        state.dogs.push(...dogsFromServer)
        return
      }

      const dogsFromClient = await loadDogsFromClient()

      if (dogsFromClient.length) {
        state.dogs.length = 0
        state.dogs.push(...dogsFromClient)
        return
      }

      await createDog()
    }

    const loadBreeds = async (): Promise<void> => {
      const response = await client.bab.getDogBreeds()

      if (response.status === 200) {
        state.breeds.push(...response.body)
      }
    }

    const loadHealthIssues = async (): Promise<void> => {
      const response = await client.bab.getDogHealthIssues()

      if (response.status === 200) {
        state.healthIssues.push(...response.body)
      }
    }

    const loadFoodAllergens = async (): Promise<void> => {
      const response = await client.bab.getDogFoodAllergens()

      if (response.status === 200) {
        state.foodAllergens.push(...response.body)
      }
    }

    const loadFoodTypes = async (): Promise<void> => {
      const response = await client.bab.getDogFoodTypes()

      if (response.status === 200) {
        state.foodTypes.push(...response.body)
      }
    }

    const load = async (): Promise<void> => {
      try {
        await Promise.all([loadDogs(), loadBreeds(), loadHealthIssues(), loadFoodAllergens(), loadFoodTypes()])
      } finally {
        state.loaded = true
      }
    }

    const hasDogs = (): boolean => {
      return stateStore.load().dogs.length > 0
    }

    return {
      createDog,
      removeDog,
      updateDog,
      associateDogs,
      load,
      hasDogs,
      $reset,

      breeds: state.breeds,
      dogs: orderedDogs,
      healthIssues: state.healthIssues,
      foodAllergens: state.foodAllergens,
      foodTypes: state.foodTypes,
    }
  })
}

export const useDogsStore = createDogsStore()
