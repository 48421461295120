import { type ComputedRef, type Ref, ref } from 'vue'
import type { StepName } from '@/stores/phoenix/steps'

export interface IStep {
  name: StepName
  busy: Ref<boolean>
  valid: Ref<boolean> | ComputedRef<boolean>
  getNextStep(): IStep | undefined
  getPreviousStep(): IStep | undefined

  // Some steps may need to trigger an async action before moving to the next step. Eg verifying whether a postcode is serviced
  submit?: () => Promise<void>
}

export type IStepConstructor = () => IStep

export type SaveHandler<R> = () => Promise<R>

export type ModifySchema<T, R> = Omit<T, keyof R> & R

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStep = () => {
  const busy = ref(false)

  const execAsync = async <R = void>(fn: SaveHandler<R>): Promise<R> => {
    busy.value = true

    const result = await fn()

    busy.value = false

    return result
  }

  return {
    execAsync,
    busy,
  }
}
