<script setup lang="ts">
import { type Component, computed, ref } from 'vue'
import StepTransition from './StepTransition.vue'
import NameStep from './steps/NameStep.vue'
import LocationStep from './steps/LocationStep.vue'
import UnservicedLocationStep from './steps/UnservicedLocationStep.vue'
import GenderStep from './steps/GenderStep.vue'
import BreedStep from './steps/BreedStep.vue'
import AgeStep from './steps/AgeStep.vue'
import WeightStep from './steps/WeightStep.vue'
import BodyShapeStep from './steps/BodyShapeStep.vue'
import ActivityStep from './steps/ActivityStep.vue'
import FussinessStep from './steps/FussinessStep.vue'
import PreviouslyFedStep from './steps/PreviouslyFedStep.vue'
import AllergiesStep from './steps/AllergiesStep.vue'
import LoadingPlanStep from './steps/LoadingPlanStep.vue'
import NoRecipesStep from './steps/NoRecipesStep.vue'
import UserStep from './steps/UserStep.vue'
import RecipesStep from './steps/RecipesStep.vue'
import PlanStep from './steps/PlanStep.vue'
import TreatsStep from './steps/TreatsStep.vue'
import CheckoutStep from './steps/CheckoutStep.vue'
import HealthIssuesStep from './steps/HealthIssuesStep.vue'
import NotFound from '@/components/NotFound.vue'
import { StepName, useStepsStore } from '@/stores/phoenix/steps'

const stepComponents = new Map<StepName, Component>([
  [StepName.Name, NameStep],
  [StepName.Location, LocationStep],
  [StepName.UnservicedLocation, UnservicedLocationStep],
  [StepName.Gender, GenderStep],
  [StepName.Breed, BreedStep],
  [StepName.Age, AgeStep],
  [StepName.Weight, WeightStep],
  [StepName.BodyShape, BodyShapeStep],
  [StepName.Activity, ActivityStep],
  [StepName.Fussiness, FussinessStep],
  [StepName.PreviouslyFed, PreviouslyFedStep],
  [StepName.Allergies, AllergiesStep],
  [StepName.HealthIssues, HealthIssuesStep],
  [StepName.LoadingPlan, LoadingPlanStep],
  [StepName.NoRecipes, NoRecipesStep],
  [StepName.User, UserStep],
  [StepName.Recipes, RecipesStep],
  [StepName.Plan, PlanStep],
  [StepName.Treats, TreatsStep],
  [StepName.Checkout, CheckoutStep],
])

const stepsStore = useStepsStore()
const direction = computed(() => stepsStore.direction)
const sessionId = ref(Date.now())

const stepComponent = computed(() => {
  const component = stepComponents.get(stepsStore.currentStep?.name)

  if (component) {
    return component
  }

  return NotFound
})
</script>

<template>
  <StepTransition :key="stepsStore.currentStepName" :direction="direction">
    <component
      :is="stepComponent"
      :key="sessionId"
      :step="stepsStore.currentStep"
      @previous="stepsStore.goToPreviousStep"
      @next="stepsStore.goToNextStep"
    />
  </StepTransition>
</template>
