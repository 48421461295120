import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import type { DogBreed, DogEntity, DogFoodAllergen, DogFoodType, DogHealthIssue } from '@lyka/bab-api-contracts/src/bab'
import { useStateStore } from './state'
import { useClient } from '@/services/useClient'
import { useAuth } from '@/composables/phoenix/auth'

interface Dependencies {
  client?: typeof useClient
  state?: typeof useStateStore
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createDogsStore = ({ client = useClient, state = useStateStore }: Dependencies = {}) => {
  return defineStore('dogs-phoenix', () => {
    const dogs = ref<DogEntity[]>([])
    const breeds = ref<DogBreed[]>([])
    const healthIssues = ref<DogHealthIssue[]>([])
    const foodAllergens = ref<DogFoodAllergen[]>([])
    const foodTypes = ref<DogFoodType[]>([])

    const $reset = (): void => {
      dogs.value = []
    }

    watch(
      dogs,
      () => {
        state().setProperty(
          'dogs',
          dogs.value.map((dog) => dog.identifier),
        )
      },
      {
        deep: true,
      },
    )

    // We don't actually need to destroy the dog
    const removeDog = (id: string): void => {
      dogs.value = dogs.value.filter((dog) => dog.identifier !== id)
    }

    const associateDog = async (dog: DogEntity): Promise<void> => {
      const userAccessToken = useAuth().getAccessToken()

      if (!userAccessToken) {
        return
      }

      await client().bab.associateAnimalWithUser({ body: { animalId: dog.identifier, userAccessToken } })
    }

    const associateDogs = async (): Promise<void> => {
      const userAccessToken = useAuth().getAccessToken()

      if (!userAccessToken) {
        throw new Error('User is not authenticated')
      }

      const requests = []

      for (const dog of dogs.value) {
        requests.push(associateDog(dog))
      }

      await Promise.allSettled(requests)
    }

    const createDog = async (): Promise<DogEntity | undefined> => {
      const response = await client().bab.createDog({
        body: {},
      })

      if (response.status === 201) {
        dogs.value.push(response.body)

        // If the user is authenticated, associate the dog with the user
        await associateDog(response.body)

        return response.body
      }
    }

    const updateDog = async (dog: DogEntity, { ...props }: Partial<DogEntity>): Promise<void> => {
      Object.assign(dog, props)

      await client().bab.updateDog({
        body: {
          animalId: dog.identifier,
          animal: props,
        },
      })
    }

    const loadDogs = async (): Promise<void> => {
      const ids = state().getProperty('dogs')

      if (!ids || ids.length === 0) {
        await createDog()
      }

      const promises = ids.map(async (id) => {
        const response = await client().bab.getDog({
          query: {
            animalId: id,
          },
        })

        if (response.status === 200) {
          dogs.value.push(response.body)
        }
      })

      await Promise.all(promises)

      // Just to make sure each reload the order stays the same
      dogs.value = dogs.value.sort((a, b) => a.identifier.localeCompare(b.identifier))
    }

    const loadBreeds = async (): Promise<void> => {
      const response = await client().bab.getDogBreeds()

      if (response.status === 200) {
        breeds.value = response.body
      }
    }

    const loadHealthIssues = async (): Promise<void> => {
      const response = await client().bab.getDogHealthIssues()

      if (response.status === 200) {
        healthIssues.value = response.body
      }
    }

    const loadFoodAllergens = async (): Promise<void> => {
      const response = await client().bab.getDogFoodAllergens()

      if (response.status === 200) {
        foodAllergens.value = response.body
      }
    }

    const loadFoodTypes = async (): Promise<void> => {
      const response = await client().bab.getDogFoodTypes()

      if (response.status === 200) {
        foodTypes.value = response.body
      }
    }

    const load = async (): Promise<void> => {
      await Promise.all([loadDogs(), loadBreeds(), loadHealthIssues(), loadFoodAllergens(), loadFoodTypes()])
    }

    return {
      createDog,
      removeDog,
      updateDog,
      associateDogs,
      load,
      $reset,

      breeds,
      dogs,
      healthIssues,
      foodAllergens,
      foodTypes,
    }
  })
}

export const useDogsStore = createDogsStore()
