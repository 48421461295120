<script setup lang="ts">
import { computed } from 'vue'
import { LykaFieldList, LykaRadio } from '@lyka/ui'
import slugify from 'slugify'
import type { DogEntity, DogHealthIssue } from '@lyka/bab-api-contracts/src/bab'
import StepHeading from '@/components/StepHeading.vue'

const props = defineProps<{
  dog: DogEntity
  healthIssues: DogHealthIssue[]
}>()

const emits = defineEmits<{
  (e: 'update', dog: DogEntity, healthIssues: string[]): void
}>()

// const illnessDescription = (illness: Illness): string => {
//   const dogName = props.dog.name

//   return dogName ? getIllnessDescription(illness, dogName) : ''
// }

// const dogHasIllnesses = computed<boolean>(() => {
//   return !!props.dog.healthIssues?.length
// })

const dogHealthIssues = computed({
  get() {
    return props.dog.healthIssues
  },
  set(value) {
    if (value) {
      emits('update', props.dog, value)
    }
  },
})

// const dogUnknownIllness = computed({
//   get() {
//     return props.dog.unknownIllness
//   },
//   set(value) {
//     emits('update', props.dogIndex, {
//       ...props.dog,
//       unknownIllness: value,
//     })
//   },
// })

const getIllnessSlug = (illnessName: string): string => slugify(illnessName, { lower: true, strict: true })
</script>

<template>
  <StepHeading> Does {{ dog.name }} have any health issues? </StepHeading>
  <!--
  <LykaFieldList>
    <LykaRadio
      v-model="dogHasIllnesses"
      :input-value="true"
      size="lg"
      type="button"
      block
      label="Yes"
      :name="`has-illnesses-${dog.identifier}`"
    />
    <LykaRadio
      v-model="dogHasIllnesses"
      :input-value="false"
      size="lg"
      type="button"
      block
      label="No"
      :name="`has-illnesses-${dog.identifier}`"
    />
  </LykaFieldList> -->

  <div>
    <div class="tw-flex tw-justify-center tw-items-center tw-h-24 first:tw-hidden">
      <div class="tw-h-px tw-w-52 tw-bg-input" />
    </div>

    <StepHeading> Please select any health conditions that apply to {{ dog.name }} </StepHeading>

    <h3 class="tw-h3" />

    <LykaFieldList>
      <LykaRadio
        v-for="healthIssue in healthIssues"
        :id="`illnesses-${dog.identifier}-${getIllnessSlug(healthIssue.name)}`"
        :key="healthIssue.identifier"
        v-model="dogHealthIssues"
        :name="`illnesses-${dog.identifier}`"
        :input-value="healthIssue.identifier"
        :label="healthIssue.name"
        type="fancy"
        size="lg"
        checkbox
      />
      <!-- <LykaRadio
        v-model="dogUnknownIllness"
        label="None of the above"
        :name="`illnesses-${dogIndex}`"
        type="fancy"
        size="lg"
        :input-value="true"
        checkbox
      /> -->
    </LykaFieldList>
  </div>
</template>
