import { initContract } from '@ts-rest/core'
import { babContract as bab } from './bab'
import { healthContract as health } from './health'
export { type AppRouter } from '@ts-rest/core'

const c = initContract()

export const contract = c.router({
  bab,
  health,
})

export { bab, health }
