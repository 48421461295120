import { ref } from 'vue'

interface Tokens {
  userAccessToken: string
  userRefreshToken: string
}

const LOCALSTORAGE_TOKEN_KEY = 'Lyka::Tokens'

const loadTokens = (): Tokens | undefined => {
  try {
    const json = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)

    if (json) {
      return JSON.parse(json)
    }
  } catch {
    return undefined
  }
}

const saveTokens = (tokens: Tokens): void => {
  try {
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, JSON.stringify(tokens))
  } catch {}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAuth = () => {
  const tokens = ref<Tokens | undefined>(loadTokens())

  const setTokens = (newTokens: Tokens): void => {
    tokens.value = newTokens

    saveTokens(newTokens)
  }

  const logout = (): void => {
    tokens.value = undefined
  }

  const getAccessToken = (): string | undefined => {
    return tokens.value?.userAccessToken
  }

  return {
    getAccessToken,
    setTokens,
    logout,
  }
}
