import z from 'zod'
import { type DogEntity, DogProperties } from '@lyka/bab-api-contracts/src/bab'
import { computed } from 'vue'
import { type IStep, useStep } from './step'
import { useLocationStep } from './location'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'

export const MAX_DOGS = 10

const StepSchema = z
  .array(
    z.object({
      name: DogProperties.shape.name,
    }),
  )
  .min(1)
  .max(MAX_DOGS)

export type NameStepData = z.infer<typeof StepSchema>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineNameStep = () => {
  const { busy, execAsync } = useStep()
  const dogsStore = useDogsStore()

  const valid = computed(() => {
    return StepSchema.safeParse(dogsStore.dogs).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return undefined
  }

  const getNextStep = (): IStep | undefined => {
    return useLocationStep()
  }

  const updateName = async (dog: DogEntity, name: string): Promise<void> =>
    execAsync(() => {
      return dogsStore.updateDog(dog, { name })
    })

  const createDog = async (): Promise<DogEntity | undefined> => execAsync(async () => dogsStore.createDog())

  const removeDog = async (id: string): Promise<void> => execAsync(async () => dogsStore.removeDog(id))

  const load = async (): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
  }

  return {
    name: StepName.Name,
    busy,
    dogs: dogsStore.dogs,
    valid,
    createDog,
    removeDog,
    getPreviousStep,
    getNextStep,
    updateName,
    load,
  }
}

export type NameStep = ReturnType<typeof defineNameStep>

export const useNameStep = registerStep(defineNameStep)
