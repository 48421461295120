import z from 'zod'
import { computed, ref } from 'vue'
import { useGenderStep } from './gender'
import { type IStep, useStep } from './step'
import { registerStep } from './stepRegistry'
import { useNameStep } from './name'
import { useUnservicedLocationStep } from './unservicedLocation'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'
import { useClient } from '@/services/useClient'
import { postcodeSchema } from '@/schemas/postcode'
import { useStateStore } from '@/stores/state'

const StepSchema = z.object({
  postcode: postcodeSchema,
  suburb: z.string().min(1),
})

export type LocationStepData = z.infer<typeof StepSchema>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const defineLocationStep = () => {
  const { busy } = useStep()
  const dogsStore = useDogsStore()
  const client = useClient()
  const state = useStateStore()

  const serviceable = ref<boolean>()

  const location = computed({
    get() {
      return state.getProperty('location')
    },
    set(value) {
      state.setProperty('location', value)

      // Reset serviceable status when location changes
      serviceable.value = undefined
    },
  })

  const postcode = ref(location.value.postcode ?? '')
  const suburb = ref(location.value.suburb ?? '')

  const valid = computed(() => {
    return StepSchema.safeParse({
      postcode: postcode.value,
      suburb: suburb.value,
    }).success
  })

  const getPreviousStep = (): IStep | undefined => {
    return useNameStep()
  }

  const getNextStep = (): IStep | undefined => {
    if (serviceable.value === false) {
      return useUnservicedLocationStep()
    }

    return useGenderStep()
  }

  const updatePostcode = (value: string): void => {
    postcode.value = value

    location.value = {
      postcode: postcode.value,
      suburb: suburb.value,
    }
  }

  const updateSuburb = (value: string): void => {
    suburb.value = value

    location.value = {
      postcode: postcode.value,
      suburb: suburb.value,
    }
  }

  const checkLocationServiceable = async (): Promise<boolean> => {
    const response = await client.bab.getSuburbServiceable({
      query: {
        postcode: postcode.value,
        suburb: suburb.value,
      },
    })

    if (response.status === 200) {
      return response.body.serviceable
    }

    return false
  }

  const submit = async (): Promise<void> => {
    serviceable.value = await checkLocationServiceable()
  }

  return {
    name: StepName.Location,
    busy,
    valid,
    postcode,
    suburb,
    dogs: dogsStore.dogs,
    submit,
    updatePostcode,
    updateSuburb,
    getPreviousStep,
    getNextStep,
  }
}

export type LocationStep = ReturnType<typeof defineLocationStep>

export const useLocationStep = registerStep(defineLocationStep)
