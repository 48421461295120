<script setup lang="ts">
import { computed } from 'vue'
import { LykaButton, LykaInput, LykaMessage } from '@lyka/ui'
import type { DogEntity } from '@lyka/bab-api-contracts/src/bab'
import StepNavigation from '../StepNavigation.vue'
import PlusIcon from '../icons/PlusIcon.vue'
import StepHeading from '../StepHeading.vue'
import StepContainer from '@/components/StepContainer.vue'
import BeenHereBeforeToggle from '@/components/BeenHereBeforeToggle.vue'
import { MAX_DOGS, type NameStep } from '@/steps/name'

const props = defineProps<{
  step: NameStep
}>()

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'previous'): void
}>()

const updateName = (dog: DogEntity, name: string): void => {
  props.step.updateName(dog, name)
}

const addDog = async (): Promise<void> => {
  await props.step.createDog()
}

const canAddDog = computed(() => {
  return props.step.dogs.length < MAX_DOGS
})

const canRemoveDog = computed(() => {
  return props.step.dogs.length > 1
})

const removeDog = async (id: string): Promise<void> => {
  if (!canRemoveDog.value) {
    return
  }

  await props.step.removeDog(id)
}

const disabled = computed(() => {
  return !props.step.valid.value || props.step.busy.value
})
</script>

<template>
  <StepContainer id="step-name">
    <StepHeading>What's your dog's name?</StepHeading>
    <form id="form-name" v-submit class="tw-space-y-4" @submit.prevent="emit('next')">
      <LykaInput
        v-for="(dog, index) in step.dogs"
        :key="dog.identifier"
        :model-value="dog.name ?? ''"
        :name="`name-${index}`"
        placeholder="Enter your pupper's name"
        required
        pattern="[\p{Letter}\s\d]+"
        spellcheck="false"
        error-message="Please provide your pupper's name"
        size="lg"
        autocapitalize
        @change="(value) => updateName(dog, value as string)"
      >
        <template #button>
          <LykaButton
            v-if="canRemoveDog"
            variant="cream"
            class="tw-mr-1"
            size="xs"
            type="button"
            title="Remove"
            :animate="false"
            @click="removeDog(dog.identifier)"
          >
            Remove
          </LykaButton>
        </template>
      </LykaInput>

      <div v-if="canAddDog" class="tw-mt-4">
        <LykaButton id="add-dog" block size="lg" variant="subtle" @click="addDog">
          <template #iconLeft>
            <PlusIcon />
          </template>
          Add another dog
        </LykaButton>
      </div>

      <StepNavigation previous-hidden :submit="true" form="form-name" :next-disabled="disabled" />
    </form>

    <aside class="tw-space-y-8 tw-mt-12">
      <LykaMessage>
        👋 Create a tailored meal plan for your dog in 2 minutes by answering a few simple questions.
      </LykaMessage>

      <BeenHereBeforeToggle />
    </aside>
  </StepContainer>
</template>
